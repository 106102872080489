@media (min-width: 1200px) and (max-width: 1400px) {
  .side-menu-with-body {
    .side-menu-container {
      // margin: 25px 0 25px 25px;
      .side-menu {
        // min-width: 260px;
        // max-width: 260px;
      }
    }

    .body-container {
      padding: 25px;

      .c-table .ant-table {
        font-size: 13px;
      }

      .cards {
        .card {
          padding: 20px 30px;

          .card-circles {
            width: 80px;
            height: 130px;
            position: absolute;
            top: -33px;
            right: -23px;
          }

          .card-logo {
            width: 80px;
            height: 170px;
            opacity: 0.1;
            right: 45px;
          }

          h4 {
            font-size: 30px;
          }
        }
      }

      .employee-info {
        flex-direction: column;

        .info {
          margin-left: 0;
          margin-top: 20px;
          padding: 10px 30px;
        }
      }
    }
  }
}

@media (min-width: 280px) and (max-width: 991px) {
  .responsive {
    .auth-container {
      position: relative;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;

      .auth-container-left {
        position: absolute;
        top: 0;
        text-align: center;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        max-height: 90%;
        border-radius: 0 0 40px 40px;
        min-width: 280px;
        text-align: justify;

        .auth-container-left-header {
          padding: 15px 50px;

          .auth-container-left-header-logo {
            width: 200px;
            height: 60px;
          }
        }

        .auth-container-left-body {
          padding: 50px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          flex: 1;
        }

        .auth-container-left-footer {
          padding: 15px 50px;

          .auth-container-left-footer-links {
          }
        }
      }

      .auth-container-right {
        width: 100%;
        height: 100%;

        h1 {
          font-size: 46px;
          line-height: 60px;
          letter-spacing: -3px;
          width: 100%;
          margin-bottom: 0;
          margin: 0 10%;
        }
      }
    }

    .c-form,
    .inner-form {
      &.inline-form-2 {
        > div {
          min-width: calc(100% - 15px) !important;
        }
      }

      &.inline-form-3 {
        > div {
          min-width: calc(100% - 15px) !important;
        }
      }

      &.inline-form-4 {
        > div {
          min-width: calc(100% - 15px) !important;
        }
      }

      &.inline-form-5 {
        > div {
          min-width: calc(100% - 15px) !important;
        }
      }

      &.inline-form-6 {
        > div {
          min-width: calc(100% - 15px) !important;
        }
      }

      &.inline-form-7 {
        > div {
          min-width: calc(100% - 15px) !important;
        }
      }
    }

    .two-card-view {
      .second-card-view {
        .second-card-view-header {
          min-height: auto;
        }
      }
    }

    .w-10 {
      width: 100% !important;
    }

    .w-20 {
      width: 100% !important;
    }

    .w-30 {
      width: 100% !important;
    }

    .w-40 {
      width: 100% !important;
    }

    .w-50 {
      width: 100% !important;
    }

    .w-60 {
      width: 100% !important;
    }

    .w-70 {
      width: 100% !important;
    }

    .w-80 {
      width: 100% !important;
    }

    .w-90 {
      width: 100% !important;
    }
  }
}

@media (min-width: 280px) and (max-width: 767px) {
  .responsive {
    .dashboard {
      .cards-top {
        .three-cards {
          flex-basis: 100%;

          .card {
            min-width: 240px;
          }
        }

        .card-top-bottom {
          hr {
            flex-basis: 100%;
          }

          h3 {
            flex-basis: 100%;
          }
        }
      }
    }

    .centivPage {
      .cards-top {
        .three-cards {
          flex-basis: 100%;

          .card {
            min-width: 240px;
          }
        }

        .centivCards {
          .card {
            min-width: 250px;
          }
        }

        .card-top-bottom {
          hr {
            flex-basis: 100%;
          }

          h3 {
            flex-basis: 100%;
          }
        }
      }
    }

    .auth-container {
      .auth-container-right {
        height: 100%;

        h1 {
          // width: calc(50% + 100px);
          font-size: 40px;
          line-height: 30px;
          margin: 10px 40px;
        }
      }
    }

    .page-nav {
      h1 {
        height: 25px;
        overflow: hidden;
      }
    }

    .flex-container {
      flex-direction: column;

      .flex-left {
        width: 100%;
        padding: 0;
      }

      .flex-right {
        width: 100%;
      }
    }

    .dashboard {
      .c-section-cards {
        flex-direction: column;
        margin: 0;

        .c-section-card {
          margin: 0 0 15px 0;
          width: 100%;
          min-height: 120px;
          padding: 15px;

          .c-section-card-title {
            font-size: 24px;
          }

          .c-section-card-sub-title {
            font-size: 14px;
          }

          &:after {
            width: 52px;
            height: 52px;
            background-size: 52px 52px;
          }
        }
      }
    }

    .c-button {
      font-size: 14px;
      padding: 5px 12px;
    }

    .hidden-mobile {
      display: none !important;
    }

    .show-mobile {
      display: flex !important;
    }

    .display-web {
      display: flex;
    }

    .c-tabs {
      .ant-tabs-bar {
        padding: 0 15px;
      }

      .content-container {
        padding: 20px 20px;
      }
    }

    .details-tab-container {
      .user-info {
        li {
          padding: 10px 0;
          flex-direction: column;

          span {
            &:first-child {
              font-size: 12px;
            }
          }

          &:last-child {
          }
        }
      }

      .user-list {
        flex-direction: column;

        .user-list-item {
          width: 100%;
        }
      }
    }

    .two-card-view {
      .second-card-view {
        .c-tabs {
          .ant-tabs-nav {
            padding: 0 15px;

            .ant-tabs-tab {
              .ant-tabs-tab-btn {
                font-size: 12px;
              }
            }
          }
        }

        .second-card-view-header {
          flex-direction: column;
          align-items: flex-start;
          position: relative;

          .second-card-view-header-content {
            margin-top: 10px;
          }

          .close {
            position: absolute;
            right: 15px;
          }

          .with-out-background-responsive {
            position: absolute;
            right: 30px;
            margin: -3px 22px 0 0;
          }
        }
      }
    }

    .order-histories {
      .order-history-cards {
        flex-direction: column;
        margin: 0 0 25px;

        .order-history-card {
          width: 100%;
          padding: 10px;
          margin: 0 0 10px;

          span {
            font-size: 14px;

            &:first-child {
              margin-bottom: 3px;
            }
          }
        }
      }

      .order-list {
        .order-list-item {
          flex-direction: column;

          .order-list-item-container {
            width: 100%;

            img {
              width: 62px;
              height: 62px;
              margin-right: 10px;
            }

            .content {
              h6 {
                font-size: 13px;
              }

              p {
                font-size: 11px;
              }
            }

            &.order-list-order-content {
              padding: 10px 0;
              margin: 10px 0;
              border-left: none;
              border-right: none;
              border-top: 1px solid #e8ebf0;
              border-bottom: 1px solid #e8ebf0;
            }

            &.driver-profile {
              padding: 0;

              .driver-profile-detail {
                margin-bottom: 15px;
              }

              .image-container {
                margin-right: 10px;
              }

              .c-button {
                font-size: 12px;
              }
            }
          }
        }
      }
    }

    .approvals {
      .approval-list {
        .approval-list-item {
          flex-direction: column;

          .approval-list-item-content {
            margin: 0 0 5px 0;
            padding: 0;
            width: 100%;

            .text {
              font-size: 12px;
            }
          }

          .button-groups {
            width: 100%;
            justify-content: flex-end;

            .c-button {
              font-size: 12px;
              padding: 2px 12px;
              min-height: 32px;
            }
          }
        }
      }
    }

    .list {
      .list-item {
        flex-direction: column;
        align-items: flex-start;

        .text {
          margin: 0 0 5px;
          width: 100%;
        }

        .button-groups {
          width: 100%;
          margin: 5px 0 0;

          .c-button {
            flex: 1;
          }
        }
      }
    }

    .appointment-list {
      .appointment-list-item {
        width: 100%;

        ul {
          li {
            .title {
              font-size: 12px;
            }

            .text {
              font-size: 14px;
            }
          }
        }
      }
    }

    .doctor-procedures-list {
      flex-direction: column;

      .doctor-procedures-list-item {
        width: calc(100% - 30px);

        .doctor-procedures-list-item-title {
          font-size: 14px;
        }

        .doctor-procedures-content-list {
          flex-direction: column;

          .doctor-procedures-content-list-item {
            width: 100%;
            margin-bottom: 10px;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .form-page {
      margin: 25px 0 0;
      padding: 25px;

      .form-container {
        .form-list-title {
          font-size: 16px;
          margin: 0 0 25px;
        }

        .form-list {
          .form-list-item {
            width: 100%;
            padding: 0;
          }
        }
      }
    }
  }

  .ant-drawer {
    &.custom-size {
      .ant-drawer-content-wrapper {
        width: 100% !important;
      }
    }

    .ant-drawer-content-wrapper {
      width: 100% !important;
    }
  }

  .user-detail-view {
    .user-image-with-name {
      img {
        width: 62px;
        height: 62px;
      }

      h4 {
        font-size: 16px;
      }
    }

    ul {
      li {
        flex-direction: column;

        span {
          font-size: 12px;

          &:last-child {
            font-size: 14px;
          }
        }

        &.license {
          flex-direction: row;
          flex-wrap: wrap;

          img {
            width: 200px;
            margin: 10px auto 10px;

            &:last-child {
              margin-right: auto;
            }
          }
        }
      }
    }
  }

  .chat-tab-container {
    .chat-container {
      .chat {
        .chat-content {
          span {
            font-size: 9px;
          }

          p {
            font-size: 13px;
          }
        }
      }
    }
  }

  .c-modal {
    //padding: 0 !important;

    .close {
      left: auto !important;
      right: 15px;
      background-color: rgba(69, 90, 100, 1) !important;
    }

    .modal-dialog {
      //padding: 0;
      //margin: 0 15px !important;
      .modal-content {
        width: 100% !important;
        margin: 0;
        padding: 0;
        //height: auto !important;
      }
    }

    &.right-side {
      .modal-dialog {
        margin: 0 0 0 auto;
        height: 100%;
        min-width: 300px;
        transform: translate3d(0%, 0, 0) !important;
        right: -100%;
      }
    }
  }

  .two-form-item {
    flex-direction: column;

    .two-form-item-left {
      margin: 0;
      width: 100%;
    }

    .two-form-item-right {
      margin: 0;
      width: 100%;
    }
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .responsive {

    .navbar {
      .menu-button {
        display: flex;
      }
      .main-nav {
        display: flex;
        flex-direction: row;
        width: 100%;
        .navbar-left {
          h1 {
            font-size: 24px;
          }
        }
        .navbar-right {
          align-self: center;
          .btn-group {
            .button-container {
              margin: 0 15px 0 0;
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }

    .c-tabs {
      .content-container {
        padding: 20px;
      }
    }

    .appointment-list {
      margin: 0 -7.5px;

      .appointment-list-item {
        margin: 7.5px;
        width: calc(50% - 15px);

        ul {
          li {
            .title {
              font-size: 12px;
            }

            .text {
              font-size: 14px;
            }
          }
        }
      }
    }

    .doctor-procedures-list {
      display: flex;
      flex-direction: row;
      margin: -7.5px;

      .doctor-procedures-list-item {
        width: calc(50% - 30px);
        margin: 15px;

        .doctor-procedures-list-item-title {
          font-size: 14px;
        }

        .doctor-procedures-content-list {
          flex-direction: row;

          .doctor-procedures-content-list-item {
            width: 50%;
            margin-bottom: 10px;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .form-page {
      margin: 25px 0 0;
      padding: 25px;

      .form-container {
        .form-list-title {
          font-size: 16px;
          margin: 0 0 25px;
        }

        .form-list {
          .form-list-item {
            width: 50%;
            padding: 0 15px;
          }
        }
      }
    }
  }

  .ant-drawer {
    &.custom-size {
      .ant-drawer-content-wrapper {
        width: 80% !important;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1450px) {
  .responsive {
    .dashboard {
      .cards-top {
        .card-top-bottom {
          justify-content: space-between;

          hr {
            flex-basis: 100%;
          }

          h3 {
          }

          .dateFilter {
          }
        }
      }

      .section-filter {
        padding: 0;
      }

      .c-section-cards {
        flex-wrap: wrap;
        margin: 0 -7.5px 25px;

        .c-section-card {
          margin: 0 7.5px 15px;
          width: calc(50% - 15px);
          min-height: 120px;
          padding: 15px;

          .c-section-card-title {
            font-size: 24px;
          }

          .c-section-card-sub-title {
            font-size: 14px;
          }

          &:after {
            width: 52px;
            height: 52px;
            background-size: 52px 52px;
          }
        }
      }
    }

    .centivPage {
      .cards-top {
        .three-cards {
          flex-basis: 100%;
        }

        .card-top-bottom {
          justify-content: space-between;

          hr {
            flex-basis: 100%;
          }
        }
      }

      .section-filter {
        padding: 0;
      }

      .c-section-cards {
        flex-wrap: wrap;
        margin: 0 -7.5px 25px;

        .c-section-card {
          margin: 0 7.5px 15px;
          width: calc(50% - 15px);
          min-height: 120px;
          padding: 15px;

          .c-section-card-title {
            font-size: 24px;
          }

          .c-section-card-sub-title {
            font-size: 14px;
          }

          &:after {
            width: 52px;
            height: 52px;
            background-size: 52px 52px;
          }
        }
      }
    }
  }

  .map-parent-container {
    height: calc(100% - 165px);
  }
}

@media (min-width: 993px) and (max-width: 1400px) {
  .responsive {
    .auth-container {
      .auth-container-left {
        height: 100%;
      }

      .auth-container-right {
        height: 100%;
      }
    }
  }
}

@media (max-width: 1400px) {
  .topContainer {
    flex-direction: column;
  }

  .rightContainer {
    border: none !important;

    padding: 0px 10px !important;
  }
}

@media (max-width: 1500px) {
  .right-side {
    .selected {
      flex-direction: column;
    }
  }
}

@media (max-width: 1100px) {
  .list-container {
    flex-direction: column;
  }
}

@media (min-width: 280px) and (max-width: 992px) {
  .responsive {
    .side-menu {
      .side-menu-header {
        .logo-container {
          .logo {
            margin: 0 10px auto;
          }
        }
      }
    }

    .side-menu-with-body {
      position: relative;
      width: 100%;

      .side-menu-container {
        position: fixed;
        left: -100%;
        top: 0;
        bottom: 0;
        right: 0;
        width: 250px;
        min-width: 250px;
        z-index: 11;
        transition: 0.3s;

        .side-menu {
          .side-menu-header-button {
            display: flex;
          }
        }
      }

      .body-container {
        width: calc(100% - 80px);
        padding: 10px;

        .page {
          padding: 10px;
        }

        .c-table {
          .ant-table-title {
            width: 100%;
          }
        }

      }

      &.left-drawer-open {
        .side-menu-container {
          left: 0;
        }
      }

      &.right-drawer-open {
        //.side-menu-container {
        //  left: 0;
        //}
        .two-card-view {
        }
      }

      &.open {
        .content-overlay {
          visibility: visible;
          opacity: 1;
        }
      }
    }

    .page-nav {
      align-items: center;
      padding: 20px 0;

      h1 {
        font-size: 20px;
        margin-right: 15px;
      }
    }

    .page-title {
      font-size: 18px;
      margin: 0 0 20px 0;
    }

    .two-card-view {
      margin: 0;
      position: relative;
      overflow: hidden;
      border-top-right-radius: 15px;
      border-top-left-radius: 15px;

      .first-card-view {
        width: 100%;
        border-top-right-radius: 15px;
        border-top-left-radius: 15px;

        .first-card-view-header {
          padding: 15px;
          min-height: auto;
          align-items: center;

          h1 {
            font-size: 18px;
          }
        }

        .first-card-view-filter {
          padding: 15px;
        }

        .first-card-view-body {
          padding: 0 0 15px;

          .c-user-list {
            .c-user-list-item {
              padding: 15px !important;
            }
          }
        }
      }

      .second-card-view {
        position: absolute;
        top: 0;
        bottom: 0;
        right: -100%;
        left: auto;
        z-index: 1;
        width: 100%;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        transition: 0.3s;

        .second-card-view-header {
          padding: 15px 15px;
        }
      }

      &.active {
        .second-card-view {
          right: 0;
        }
      }
    }

    .dashboard {
      .chart-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        gap: 25px;

        .ant-picker-range {
          margin-bottom: 20px;
          display: flex;
          align-self: flex-end;
          width: 33%;
          background: $picker;
        }

        .chart-body {
          padding: 0px 10px 10px 10px;
          min-height: 400px;
          height: 400px;
          width: 100%;
          min-height: 350px !important;
          height: 350px !important;

          > div {
            min-height: 350px !important;
            height: 350px !important;
          }

          canvas {
            // width: 100% !important;
            // height: auto !important;
          }
        }

        .chart-inline {
          flex-basis: 100%;
          flex-direction: column;

          .chart {
            width: calc(100% - 20px);
          }
        }

        .chart {
          width: calc(100% - 20px);
          min-height: 300px;
          height: 400px;

          .chart-head {
            padding: 30px;
          }

          .chart-dropDown {
            min-width: 10% !important;
            align-self: flex-end;
            justify-self: flex-start;
            margin-bottom: 25px;
          }
        }
      }
    }
  }
}

@media (min-width: 993px) and (max-width: 1200px) {
  .responsive {
    .page-title {
      font-size: 22px;
    }

    .page-nav {
      h1 {
        font-size: 26px;
      }
    }

    .two-card-view {
      .first-card-view {
        width: 320px;

        .first-card-view-header {
          padding: 25px;

          h1 {
            font-size: 20px;
          }

          .search-bar {
            right: 62px;

            &.active {
              width: calc(100% - 102px) !important;
            }
          }
        }

        .first-card-view-body {
          .c-user-list {
            .c-user-list-item {
              padding: 15px;
            }
          }
        }
      }

      .second-card-view {
        width: calc(100% - 280px);

        .second-card-view-header {
          padding: 20px 15px;
          min-height: 72px;
        }
      }
    }

    .details-tab-container {
      .user-list {
        flex-direction: column;

        .user-list-item {
          width: 100%;
        }
      }
    }

    .form-page {
      margin: 25px 0 0;
      padding: 25px;

      .form-container {
        .form-list-title {
          font-size: 16px;
          margin: 0 0 25px;
        }

        .form-list {
          .form-list-item {
            width: 50%;
            padding: 0 15px;
          }
        }
      }
    }
  }
}

@media (min-width: 280px) and (max-width: 425px) {
  .responsive {
    .auth-container {
      .auth-container-left {
        min-height: 100%;

        .auth-container-left-body {
          padding: 50px 20px;

          .auth-container-left-footer {
            padding: 0;
          }
        }
      }

      .auth-container-right {
        height: 100%;

        h1 {
          display: none;
          font-size: 28px;
          line-height: 30px;
          margin: 10px 20px;
        }
      }
    }

    .side-menu-with-body {
      .side-menu-container {
        width: 100%;
        min-width: 100%;
      }
    }

    .side-menu {
      min-width: 100%;
      max-width: 100%;
    }
  }

  .big-card2 {
    // margin-left: -45px;
  }

  .cardResponsive {
    margin-top: 58px !important;
    width: 300px !important;
  }
}

@media (min-width: 992px) and (max-width: 1300px) {
  .responsive {
    .side-menu-with-body {
      .body-container {

        .page {
          .c-table {
            .ant-table-title {
              width: 100%;
            }
          }
        }
      }
    }

    .w-10 {
      width: 50% !important;
    }

    .w-20 {
      width: 50% !important;
    }

    .w-30 {
      width: 50% !important;
    }

    .w-40 {
      width: 50% !important;
    }

    .w-50 {
      width: 50% !important;
    }

    .w-60 {
      width: 50% !important;
    }

    .w-70 {
      width: 50% !important;
    }

    .w-80 {
      width: 100% !important;
    }

    .w-90 {
      width: 100% !important;
    }

    .c-form,
    .inner-form {
      &.inline-form-2 {
        > div {
          min-width: calc(100% - 15px) !important;
        }
      }
      &.inline-form-3 {
        > div {
          min-width: calc(100% - 15px) !important;
        }
      }

      &.inline-form-3 {
        > div {
          min-width: calc(50% - 15px) !important;
        }
      }

      &.inline-form-4 {
        > div {
          min-width: calc(33.33% - 15px) !important;
        }
      }

      &.inline-form-5 {
        > div {
          min-width: calc(25% - 15px) !important;
        }
      }

      &.inline-form-6 {
        > div {
          min-width: calc(20% - 15px) !important;
        }
      }

      &.inline-form-7 {
        > div {
          min-width: calc(16.66% - 15px) !important;
        }
      }
    }

    .gmvdiv {
      margin-top: 60px;
      margin-bottom: 20px;

      .gmvamount {
        span {
          color: $black3;
          font-size: 24px;
          font-family: $font-bold;
          letter-spacing: -1.5px;
        }
      }
    }

    .ant-select-arrow {
      color: #000000;
    }
  }
}

@media (min-width: 1301px) and (max-width: 1800px) {
  .responsive {
    .dashboard {
      .cards-top {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 20px;

        .three-cards {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          border-radius: 7px !important;
          // margin-top: 10px;
          justify-content: flex-start;
          overflow: hidden;
          // flex-basis: 100%;
        }
      }

      .chart-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        gap: 25px;

        .ant-picker-range {
          margin-bottom: 20px;
          display: flex;
          align-self: flex-end;
          width: 33%;
          background: $picker;
        }

        .chart-body {
          padding: 0px 10px 10px 10px;
          min-height: 400px;
          height: 300px;
          width: 100%;

          > div {
            min-height: 350px;
          }

          canvas {
            // width: 100% !important;
            // height: auto !important;
          }
        }

        .chart-inline {
          flex-basis: 100%;
          // flex-direction: column;

          .chart {
            width: calc(50% - 20px);
          }
        }

        .chart {
          width: calc(100% - 20px);
          min-height: 500px;
          height: 500px;

          .chart-head {
            padding: 30px 30px 0;
          }

          .chart-dropDown {
            min-width: 10% !important;
            align-self: flex-end;
            justify-self: flex-start;
            margin-bottom: 25px;
          }
        }
      }
    }
  }
}

@media (min-width: 1600px) {
  .responsive,
  .ant-drawer {
    .dashboard {
      .cards-top {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 20px;

        .three-cards {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          border-radius: 7px !important;
          // margin-top: 10px;
          justify-content: flex-start;
          overflow: hidden;
          // flex-basis: 60%;
        }

        .dateFilter {
          .c-field-container {

          }
        }
      }

      .chart-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        gap: 25px;

        .ant-picker-range {
          margin-bottom: 20px;
          display: flex;
          align-self: flex-end;
          width: 33%;
          background: $picker;
        }

        .chart-body {
          padding: 0px 10px 10px 10px;
          min-height: 380px;
          // height: 500px;
          width: 100%;

          > div {
            min-height: 350px;
            // height: 350px !important;
          }

          canvas {
            // width: 100% !important;
            // height: auto !important;
          }
        }

        .chart-inline {
          .chart {
            width: calc(50% - 20px);
          }
        }

        .chart {
          width: 100%;
          min-height: 500px;
          // height: 400px;

          .chart-head {
            padding: 30px;
          }

          .chart-dropDown {
            min-width: 10% !important;
            align-self: flex-end;
            justify-self: flex-start;
            margin-bottom: 25px;
          }
        }
      }
    }

    .centivPage {
      .cards-top {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 20px;

        .three-cards {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          border-radius: 7px !important;
          // margin-top: 10px;
          justify-content: flex-start;
          overflow: hidden;
          // flex-basis: 60%;
        }

        .dateFilter {
          .c-field-container {
          }
        }
      }

      .chart-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        gap: 25px;

        .ant-picker-range {
          margin-bottom: 20px;
          display: flex;
          align-self: flex-end;
          width: 33%;
          background: $picker;
        }

        .chart-body {
          padding: 0px 10px 10px 10px;
          min-height: 380px;
          // height: 500px;
          width: 100%;

          > div {
            min-height: 350px;
            // height: 350px !important;
          }

          canvas {
            // width: 100% !important;
            // height: auto !important;
          }
        }

        .chart-inline {
          .chart {
            width: calc(50% - 20px);
          }
        }

        .chart {
          width: 100%;
          min-height: 500px;
          // height: 400px;

          .chart-head {
            padding: 30px;
          }

          .chart-dropDown {
            min-width: 10% !important;
            align-self: flex-end;
            justify-self: flex-start;
            margin-bottom: 25px;
          }
        }
      }
    }

    .auth-container {
      .auth-container-left {
        max-width: 650px;
        min-width: 650px;

        .auth-container-left-header {
          padding: 50px;

          .auth-container-left-header-logo {
          }
        }

        .auth-container-left-body {
          padding: 0 50px;

          .body {
          }

          .form-title {
          }
        }

        .auth-container-left-footer {
          padding: 25px 50px;

          .auth-container-left-footer-links {
            display: flex;
            flex-direction: row;

            a {
              font-family: $font-regular;
              font-size: 14px;
              line-height: 22px;
              color: $black;
              opacity: 0.34;
              text-transform: capitalize !important;
              display: flex;
              flex-direction: row;
              align-items: center;

              &::after {
                content: "";
                display: flex;
                margin: 0 15px;
                width: 2px;
                height: 15px;
                background-color: $black;
                opacity: 0.34;
              }

              &:last-child {
                &::after {
                  display: none;
                }
              }
            }
          }
        }
      }

      .auth-container-right {
        h1 {
          font-size: 80px;
          line-height: 94px;
          letter-spacing: -3px;
          width: 50%;
          margin: 40px 100px;
        }
      }
    }

    .changePassText {
      font-size: 14px;
    }

    .otp-container {
      .otp-field {
        input {
          width: 60px !important;
          height: 60px !important;
          border-radius: 10px !important;
          border-color: #4a58d1 !important;
          margin: 10px;
          border: 1px solid;
          font-size: 14px;
        }
      }
    }

        .cardFields {
      display: flex;
      justify-content: space-between;
      
      > div {
        width: 100%;
  
        .card-field {
          input {
            width: 45px !important;
            height: 45px !important;
            border-radius: 10px !important;
            border-color: $text-color !important;
            margin: 0 6px;
            border: 1px solid;
            font-size: 12px;
          }
  
          &:first-child {
            input {
              margin-left: 0 !important;
            }
          }
        }
    }
  }

    .forgot-pass {
      font-size: 16px;
    }

    p.s-up {
      margin-top: 20px !important;
      font-size: 16px;
    }

    .c-field-container {
      margin: 15px 15px 15px 0;

      .c-field {
        .c-field-left-icon {
          width: 20px;
          margin-left: 20px;
          color: #cfcfcf;
          transition: 0.3s ease all;
        }

        .input-title {
          margin: 0 10px 8px 8px;
          color: $text-color;
          font-size: 14px;
          font-family: $font-regular;
        }

        .clear-text {
          flex: 1;
          border: none;
          background-color: transparent;
          height: 100%;
          min-height: 20px;
          font-size: 18px;
          font-family: $font-regular;
          color: $black;
          width: 100%;
          transition: 0.3s ease all;
        }

        &.with-prefix {
          .ant-input-affix-wrapper {
            .ant-input-prefix {
              border: 1.5px solid $dark-gray;
              border-right: none;
            }
          }
        }

        input {
          flex: 1;
          height: 100%;
          min-height: 45px;
          font-size: 16px;
          font-family: $font-regular;
          color: $black;

          width: 100%;
          // background: $gray;
          border: 1.5px solid $dark-gray;
          box-sizing: border-box;
          border-radius: 10px;
          transition: 0.3s ease all;
          overflow: hidden;
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 8px 18px;

          &::placeholder {
            font-size: 16px;
          }

          &:focus {
            border-width: 2px;
          }
        }

        textarea {
          height: 100px;
          padding: 10px;
          min-height: 100px;
          font-size: 16px;
        }

        .ant-picker {
          height: 45px !important;
          min-height: 45px;

          .ant-picker-suffix {
            position: absolute;
            right: 8%;
          }

          .ant-picker-clear {
            position: absolute;
            right: 8%;
          }
        }

        .ant-input-affix-wrapper {
          input {
            // padding-right: 50px;
          }
        }

        .prefix {
          position: relative;

          .ant-select {
            .ant-select-selector {
              height: 100%;
              background-color: transparent !important;

              .ant-select-selection-wrap {
                .ant-select-selection-search {
                  input {
                    width: 80%;
                    background-color: transparent !important;
                  }
                }
              }
            }
          }

          input {
            // padding-left: 75px;
          }
        }
      }

      // select,
      .ant-select {
        min-height: 45px !important;
        &.prefix {
          width: 75px !important;
        }
      }

      .ant-select-selector {
        min-height: 45px !important;
        padding: 9px 20px !important;
        font-size: 14px;
        border-radius: 10px !important;
        border-width: 1.5px !important;
        .ant-select-selection-wrap {
          .ant-select-selection-item {
            font-size: 14px;
            line-height: 45px;
          }
        }

        &.placeholder {
          font-size: 14px;
        }
      }

      .linked-establishment-select {
        line-height: 22px;
      }

    }

    .c-checkbox {
      input {
        width: 15px;
        height: 15px;
        accent-color: $primary;
      }

      label {
        font-size: 18px;
        line-height: 27px;
      }
    }

    .error {
      font-size: 14px;
      margin-top: 10px;
    }

    .button-container {
      flex: 1;
      align-items: center;
      margin: 15px 15px 15px 0;

      .input-title {
        margin: 0 10px 8px 8px;
        color: $text-color;
        font-size: 14px;
        font-family: $font-regular;
        align-self: baseline;
      }

      .c-button {
        width: 100%;
        height: 45px;
        min-height: 45px;
        max-height: 45px;
        min-width: 160px;

        &.large {
          min-width: 160px;
        }

        &:active {
          box-shadow: none;
          border-color: $white;
          transition: 0.3s ease all;
        }

        span {
          font-family: $font-regular;
          font-size: 14px;
          line-height: 16px;
          text-align: center;
          color: $white;
          white-space: nowrap;
        }
      }

      .c-button-left-icon {
        margin-right: 10px;
        width: 16px;
        height: 16px;
      }
    }

    .c-icon {
      min-width: 18px;
      min-height: 18px;
      width: 18px;
      height: 18px;

      svg {
        width: 18px;
        height: 18px;
      }

      &.large {
        width: 22px;
        height: 22px;

        svg {
          width: 22px;
          height: 22px;
        }
      }
    }

    .side-menu {
      min-width: 300px;
      max-width: 300px;
      border-radius: 0px 12px 12px 0px;

      .side-menu-header-button {
        top: 30px;

        svg {
          font-size: 24px;
        }
      }

      .side-menu-header {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 50px 40px;
        width: 100%;

        img {
          width: 100%;
        }

        .logo {
          width: 86px;
          height: 86px;
          border-bottom-right-radius: 30px;
          padding: 10px;

          img {
            width: 100%;
          }
        }

        .company-name {
          text-transform: capitalize;
          // margin-left: 20px;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          overflow-wrap: anywhere;
          font-size: 16px;
          line-height: 113.6%;
        }
      }

      .side-menu-body {
        // margin: 35px 0;

        .side-menu-list-item {
          margin-bottom: 10px;

          a {
            height: 60px;
            font-size: 14px;
            padding: 0 40px;

            svg {
              width: 20px;
              margin-right: 15px;
            }
          }

          div {
            height: 60px;
            font-size: 14px;
            padding: 0 20px;

            svg {
              width: 20px;
              font-size: 22px;
              margin-right: 15px;
            }
          }
        }
      }

      .side-menu-footer {
        .side-menu-footer-text {
          font-size: 12px;
          line-height: 18px;
          // margin-bottom: 12px;

          .sidemenuarrow {
            height: 12px;
            width: 15px;
          }
        }

        .side-menu-footer-link {
          font-size: 15px;
        }
      }

      .side-menu-background-image {
        height: 385px;
        width: 237px;
        left: 0;
        right: 0;
        bottom: -5px;
      }
    }

    .user-detail {
      margin: 0 40px 40px;
    }

    .c-table {
      max-height: 100%;

      .ant-table-title {
        padding: 0;
        padding-bottom: 30px;

        .search-view {
          flex: 0;
          width: 50%;
        }

        .card-view {
          .card {
            flex: 0;
          }
        }
      }

      .ant-table {
        font-size: 12px;

        table {
          border: 1px solid $stroke-color;

          tr {
            th {
              padding: 10px;
              font-size: 14px;
              font-family: $font-bold;
              // border-top: 1px solid $stroke-color;
              border-right: 1px solid $stroke-color;
              border-bottom: 1px solid $stroke-color;

              &:last-child {
                border-right: none;
              }
            }

            td {
              padding: 10px;
              font-family: $font-regular;
              // border-top: 1px solid $stroke-color;
              padding: 10px;
              border-right: 1px solid $stroke-color;
              font-size: 14px;
              line-height: 18px;
              border-bottom: 1px solid $stroke-color;

              > div {
                font-family: $font-regular;
                font-size: 14px;
                line-height: 18px;
              }

              .ant-table-expanded-row-fixed {
                background-color: transparent;
              }

              .bold-text {
                width: max-content;
                // padding: 5px 10px;

                &.primary-back {
                  background-color: $primary;
                  color: $white;
                }

                &.purple-back {
                  background-color: $purple-shade;
                  color: $primary;
                }

                &.gray-back {
                  background-color: $gray-shade;
                  color: $black_2;
                }

                &.green-back {
                  background-color: $green-shade;
                  color: $green;
                }
              }
            }
          }

          thead {
            tr {
              th {
                &:before {
                  display: none;
                }
              }

              &:first-child {
                th {
                  &:first-child {
                    // border-top-left-radius: 24px !important;
                  }

                  &:last-child {
                    // border-top-right-radius: 24px !important;
                  }
                }
              }
            }
          }

          tbody {
            tr {
              &:last-child {
                td {
                  // border-bottom: none;
                  // border-bottom: 1px solid $stroke-color;
                  font-family: $font-medium;
                }
              }

              td {
                &:last-child {
                  border-right: none;
                }
              }
            }
          }
        }

        .primary-color {
          color: $primary;
        }

        .green-color {
          color: $green;
        }

        .red-color {
          color: $error;
        }

        .black-color {
          color: $black_2;
        }

        .medium-font {
          font-family: $font-medium;
        }

        .bold-font {
          font-family: $font-bold;
        }
      }

      &.expand {
        .ant-table {
          table {
            border: none;
            border-top: 0.5px solid $stroke-color;
            tr {
              &.ant-table-row-level-0 {
                td {
                  border-top: 0.5px solid $stroke-color;
                  border-left: 0.5px solid $stroke-color;
                  border-right: 0.5px solid $stroke-color;
                }
              }

              &.ant-table-expanded-row-level-1 {
                td {
                  padding: 0;
                  background-color: $white !important;
                  border-bottom: none;

                  > div {
                    border-bottom: 0.5px solid $stroke-color;
                    margin-bottom: 12px;
                    background-color: $light-back;
                  }
                }
              }
            }

            thead {
              tr {
                th {
                  &:first-child {
                    border-left: 0.5px solid $stroke-color;
                  }

                  &:last-child {
                    border-right: 0.5px solid $stroke-color;
                  }
                }
              }
            }
          }
        }
      }

      .ant-table-pagination {
        &.ant-pagination {
          margin: 0;
          padding: 25px 25px;
          background-color: $light-gray;
          border-bottom-left-radius: 24px;
          border-bottom-right-radius: 24px;

          .ant-pagination-prev {
            display: none;
          }

          .ant-pagination-next {
            display: none;
          }

          .ant-pagination-item {
            min-width: 32px;
            height: 32px;
            border-radius: 18px;
            border: none;
            background-color: $gray;

            a {
              color: $dark-gray;
            }

            &.ant-pagination-item-active {
              background-color: $primary;

              a {
                color: $white;
              }
            }
          }
        }
      }

      .ant-spin-nested-loading {
        // border-radius: 24px;
        overflow: hidden;
      }
    }

    .c-form,
    .inner-form {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin: 20px 0 0;
      }

      h1 {
        font-size: 2.5em;
      }

      h2 {
        font-size: 2em;
      }

      h3 {
        font-size: 1.5em;
      }

      h4 {
        font-size: 1.17em;
      }

      // h5 {
      //   font-size: 0.83em;
      // }
      h6 {
        font-size: 0.83em;
      }

      .form-sub-title {
        margin-top: 10px;
      }
    }

    .c-preview-list {
      .preview {
        border: 1px solid $dark-gray;
        padding: 8px 10px;
        flex: 1;
        min-height: 50px;
        max-height: 50px;
        margin: 20px 20px 20px 0;
        border-radius: 10px;

        .left {
          display: flex;
          align-items: center;
          cursor: pointer;
          overflow: hidden;

          .thumbnail {
            width: 40px;
            height: 36px;
            border-radius: 8px;

            img,
            svg {
              object-fit: contain;
              width: 40px;
              height: 36px;
            }
          }

          span {
            justify-content: space-between;
            align-items: center;
            font-family: $font-medium;
            margin: 0 10px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: baseline;
            justify-content: center;

            div {
              font-size: 14px;
              line-height: 22px;
              margin-bottom: 0;
            }

            p {
              font-size: 11px;
              line-height: 100.6%;
              color: #828486;
              margin-bottom: 0;
            }
          }
        }

        .icon {
          &:hover {
            color: red;
          }
        }
      }
    }

    .ant-alert {
      border-radius: 6px;

      span {
        svg {
          font-size: 14px;
        }
      }

      .ant-alert-content {
        font-size: 12px;
      }
    }

    .c-data,
    .inner-data {
      ul {
        li {
          margin: 20px 80px 20px 0;
          min-width: 15%;

          span {
            &:first-child {
              font-size: 14px;
              line-height: 30px;
            }

            &:last-child {
              font-size: 22px;
              line-height: 30px;
            }
          }
        }

        h4 {
          font-size: 19px;
          line-height: 26px;
          color: $black;
          width: 100%;
        }
      }
    }

    hr {
      width: 100%;
      color: $text-color;
      margin: 0;
    }

    .search-view {
      min-width: 320px;

      input {
        font-size: 14px;
        border-radius: 10px;
        height: 45px;
        min-height: 45px;
      }

      svg {
        position: absolute;
        right: 15px;
        top: 11px;
        font-size: 18px;
        color: $secondary;
      }

      &.input-container {
      }
    }

    .list {
      margin: 10px 0;
      padding: 0;
      list-style: none;

      &.appointment-list {
        .list-item {
          .button-groups {
            min-width: 340px;
          }
        }
      }

      .list-item {
        .text {
          font-size: 14px;
        }
      }
    }

    .c-checkbox-group {
      .ant-checkbox-wrapper {
        display: flex;
        align-items: flex-start;

        .ant-checkbox {
          font-size: 14px;

          .ant-checkbox-inner {
            border-radius: 4px;
            width: 18px;
            height: 18px;
          }

          span {
            &:last-child {
              font-size: 14px;
            }
          }
        }
      }
    }

    .ant-select-item-option-content {
      font-size: 12px !important;
    }
  }

  .custom-table {
    width: 100%;
    max-height: 500px;

    table {
      width: 500px;
      max-height: 500px;

      tr {
        th {
          font-size: 14px;
        }

        td {
          font-size: 13px;
        }
      }
    }
  }

  .ant-modal-title {
    font-size: 18px;
  }
}

@media (max-width: 991px) {
  .terms .title h1.title-head {
    font-size: 20px;
  }
}

@media (max-width: 590px) {
  .detailsPage {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .sideContent {
    width: 100%;
    margin-right: 0;
  }
}

@media (min-width: 993px) and (max-width: 1250px) {
  .responsive {
    .dashboard {
      .cards-top {
        .dashboard-cardsEmployer {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          border-radius: 7px !important;
          // margin: 20px 0px 10px 0px;
          overflow: hidden;
          // margin: 0 -10px 45px;

          .cardEmloyer {
            // min-width: 140px;
            border-radius: 0 !important;
            background-color: $primary;
            padding: 15px 15px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            position: relative;
            // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
            overflow: hidden;
            flex: 1;
            min-height: 230px;
            justify-content: space-between;
            align-items: flex-start;

            &:first-child {
              flex: 1.8;
            }

            &:nth-child(odd) {
              background-color: $light-purple !important;
            }

            &:nth-child(even) {
              background-color: $lightest-purple !important;
            }
          }
        }
      }

      .chart-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        gap: 25px;

        .ant-picker-range {
          margin-bottom: 20px;
          display: flex;
          align-self: flex-end;
          width: 33%;
          background: $picker;
        }

        .chart-body {
          padding: 0px 10px 10px 10px;
          min-height: 400px;
          height: 400px;
          width: 100%;
          min-height: 350px !important;
          height: 350px !important;

          > div {
            min-height: 350px !important;
            height: 350px !important;
          }

          canvas {
            // width: 100% !important;
            // height: auto !important;
          }
        }

        .chart-inline {
          flex-basis: 100%;
          flex-direction: column;

          .chart {
            width: calc(100% - 20px);
          }
        }

        .chart {
          width: calc(100% - 20px);
          min-height: 300px;
          height: 400px;

          .chart-head {
            padding: 30px;
          }

          .chart-dropDown {
            min-width: 10% !important;
            align-self: flex-end;
            justify-self: flex-start;
            margin-bottom: 25px;
          }
        }
      }
    }
  }
}

@media (min-width: 1250px) and (max-width: 1350px) {
  .responsive {
    .side-menu {
      min-width: 250px;
      max-width: 250px;

      .side-menu-header {
        padding: 30px 65px 20px 65px !important;
      }
    }

    .user-detail {
      margin: 0 20px 40px;
    }

    .dashboard {
      .chart-container {
        .chart-inline {
          flex-basis: 100%;
          flex-direction: column;

          .chart {
            width: calc(100% - 20px);
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .responsive {
    .side-menu-with-body {
      .navbar {
        .menu-button {
          display: flex;
          //margin-bottom: 10px;
          align-self: flex-start;
        }
        .main-nav {
          display: flex;
          flex-direction: column;
          width: 100%;
          .navbar-left {
            margin-top: 0;
            h1 {
              font-size: 18px;
              margin: 0 5px;
            }
            p {
              margin: 10px 5px 0;
            }
          }
          .navbar-right {
            margin-bottom: 0;
            align-self: flex-start;
            .button-container {
              margin: 0;
            }
            .btn-group {
              .button-container {
                margin: 0 5px 10px;
                &:last-child {
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}

