.gmvdiv {
  margin-top: 60px;
  margin-bottom: 20px;

  .gmvamount {
    span {
      color: $black3;
      font-size: 24px;
      font-family: $font-bold;
      letter-spacing: -1.5px;
    }
  }
}

.ant-select-arrow {
  color: #000000;
}

.dashboard {
  display: flex;
  flex-direction: column;
  gap: 25px;

  .cards-top {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;

    .three-cards {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      border-radius: 7px !important;
      // margin-top: 10px;
      justify-content: flex-start;
      overflow: hidden;
      // flex-basis: 100%;

      .card {
        border-radius: 0 !important;
        background-color: $primary;
        padding: 15px 25px;
        display: flex;
        flex-direction: column;
        flex: 1;
        // min-height: 300px;
        justify-content: flex-start;
        min-width: 200px;

        // &:last-child {
        //   border-radius: 0 0 7px 0 !important;
        // }

        &:nth-child(1) {
          min-width: 220px;
        }

        // &:first-child {
        //   flex: 1;
        //   // border-top-left-radius: 7px !important;
        //   // border-bottom-left-radius: 7px !important;
        // }

        &:nth-child(odd) {
          background-color: $light-purple !important;
        }

        &:nth-child(even) {
          background-color: $lightest-purple !important;
        }

        &:last-child {
          // border-top-right-radius: 7px !important;
          // border-bottom-right-radius: 7px !important;
        }

        // span {
        //   color: $white;
        //   font-family: $font-regular;
        //   font-size: 16px;
        //   letter-spacing: -0.5px;
        // }

        h4 {
          margin: 0;
          font-size: 16px;
          font-family: $font-space-regular;
          color: $black3;
          margin-left: 15px;
        }

        span {
          color: $black3;
          font-size: 12px;
          line-height: 23px;
          font-family: $font-space-regular;
          letter-spacing: -0.5px;
          margin-bottom: 5px;
        }

        p {
          margin: 0;
          font-size: 12px;
          color: $white;
          font-family: $font-regular;
        }

        .card-circles {
          width: 100px;
          height: 150px;
          opacity: 0.2;
          position: absolute;
          top: -23px;
          right: -13px;
        }

        .balance {
          display: flex;
          flex-direction: row;
          align-items: center;

          h4 {
            letter-spacing: -0.68px;
          }
        }

        img {
          width: 20px;
          height: 20px;
          object-fit: contain;
        }

        .arrow {
          height: 15px;
          width: 15px;
          margin-left: 15px;
        }

        .card-logo {
          width: 150px;
          height: 200px;
          opacity: 0.1;
          right: 45px;
        }

        &.overview {
          width: 50%;
          background: linear-gradient(180deg, #3a3ab9 0%, #000080 100%);
          flex-direction: row;
          align-items: center;
        }

        .card-content {
          flex: 1;
        }
      }
    }

    .card-top-bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      flex-wrap: wrap;
      gap: 20px;

      hr {
        flex: 2;
      }

      h3 {
        flex: 1;
        font-size: 18px;
        font-family: $font-regular;
        color: $light-black;
        justify-content: center;
        display: flex;
        margin-bottom: 0;
      }

      .dateFilter {
        display: flex;
        flex: 1;
        align-items: flex-end;
        // margin-top: 20px;
        align-self: end;
        justify-self: flex-end;

        .c-field-container {
          margin: 0 !important;
        }
      }
    }
  }

  .dashboard-cardsEmployer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border-radius: 7px !important;
    overflow: hidden;
    // margin: 0 -10px 45px;

    .cardEmloyer {
      min-width: 170px;
      border-radius: 0 !important;
      background-color: $primary;
      padding: 15px 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
      // overflow: hidden;
      flex: 1;
      min-height: 230px;
      justify-content: space-between;
      align-items: flex-start;

      &:nth-child(2),
      &:nth-child(3) {
        flex: 1.2;
      }

      &:nth-child(2) {
        min-width: 240px;
      }

      &:nth-child(odd) {
        background-color: $light-purple !important;
      }

      &:nth-child(even) {
        background-color: $lightest-purple !important;
      }

      &:last-child {
      }

      .spent {
        color: $black3;
        font-size: 13px;
        line-height: 23px;
        font-family: $font-space-semiBold;
        letter-spacing: -0.5px;
      }

      h4 {
        margin: 0;
        font-size: 16px;
        font-family: $font-space-medium;
        color: $black3;
      }

      span {
        color: $black3;
        font-size: 12px;
        line-height: 19px;
        font-family: $font-space-regular;
        letter-spacing: 0;
      }

      p {
        margin: 0;
        font-size: 12px;
        color: $white;
        font-family: $font-regular;
      }

      .card-circles {
        width: 100px;
        height: 150px;
        opacity: 0.2;
        position: absolute;
        top: -23px;
        right: -13px;
      }

      img {
        width: 35px;
        height: 35px;
        object-fit: contain;
      }

      .card-logo {
        width: 150px;
        height: 200px;
        opacity: 0.1;
        right: 45px;
      }

      &.overview {
        width: 50%;
        background: linear-gradient(180deg, #3a3ab9 0%, #000080 100%);
        flex-direction: row;
        align-items: center;
      }

      .card-content {
        flex: 1;
      }

      // &.centiv {
      //   background: linear-gradient(180deg, #313131 0%, #000000 89.54%);
      //   justify-content: flex-end;
      // }
      // &.payd {
      //   background-color: $primary;
      //   justify-content: flex-end;
      // }
    }
  }

  .chart-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 25px;

    .ant-picker-range {
      margin-bottom: 20px;
      display: flex;
      align-self: flex-end;
      width: 33%;
      background: $picker;
    }

    .chart-body {
      padding: 0px 10px 10px 10px;
      min-height: 300px;
      height: 300px;
      width: 100%;
      overflow: hidden;

      > div {
        min-height: 300px;
      }

      &.empty {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      canvas {
        // width: 100% !important;
        // height: auto !important;
      }
    }

    .chart-inline {
      display: flex;
      flex-wrap: wrap;
      flex-basis: 50%;
      gap: 20px;

      .chart {
        width: calc(50% - 20px);
      }
    }

    .chart {
      border: 1px solid $border-light;
      border-radius: 7px;
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: space-between;
      width: 100%;
      min-height: 200px;

      .chart-head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 30px 30px 0;

        h1 {
          font-family: $font-regular;
          color: $black3;
          letter-spacing: -1px;
        }

        h5 {
          color: $black;
          margin-bottom: 0;
          font-family: $font-regular;
        }
      }

      .chart-dropDown {
        min-width: 10% !important;
        align-self: flex-end;
        justify-self: flex-start;
        margin-bottom: 25px;
      }
    }
  }
}
