@import "./auth/auth";
@import "./employer/details/employerDashboard";
@import "./centivCard/centivCard";

.inner-table {
  .ant-table {
    margin-inline: 0 !important;
    margin-block: 0 !important;
  }
}

.manageUserListContainer {
  label {
    width: calc(25% - 10px);
    margin: 2.5px 5px !important;
  }
}
