* {
  outline: none !important;
}

body,
html {
  margin: 0;
  display: flex;
  background-color: #e5e5e5;

  #root {
    flex: 1;
    height: 100%;
    display: flex;
    width: 100%;

    .app-container {
      display: flex;
      flex: 1;
      height: 100%;
      width: 100%;
    }
  }
}

.side-menu-with-body {
  display: flex;
  flex: 1;
  width: 100%;

  .side-menu-container {
    display: flex;
    background-color: $white;
    // margin: 30px 0 30px 40px;
  }

  .body-container {
    padding: 30px 40px;
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y: scroll;
    background: #ffff;
    position: relative;

    .ant-spin-nested-loading {
      display: flex;
      flex-direction: column;
      flex: 1;

      .ant-spin-container {
        display: flex;
        flex-direction: column;
        flex: 1;
      }
    }
  }
}

// dashboard form start

.page-container,
.page {
  background: $white;
  border: 1px solid $stroke-color;
  border-radius: 8px;
  // padding: 60px !important;
  width: 100%;
  //height: 100%;
  flex: 1;
  // background: #f1f2f4;
  padding: 40px;
  display: flex;
  flex-direction: column;
  box-shadow: 14px 17px 40px 4px rgba(112, 144, 176, 0.08);
  position: relative;
}

.normal-page {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.right-section {
  // padding: 0 40px;
  // display: flex;
  // flex-direction: column;

  h2 {
    // align-self: center;
    margin-bottom: 20px !important;
  }
}

.dashboard-form {
  .establishment {
    margin: 20px;

    .c-field-container {
      width: 50%;
    }
  }

  .two-inline-forms {
    display: flex !important;
    flex-wrap: wrap;
    align-items: baseline;
    justify-content: space-around;

    .column-form {
      flex: 1;

      .billing-datails {
        flex: 1;
        margin: 20px;
      }

      .contact-datails {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 20px;

        .c-field-container {
          flex: 1;
        }
      }
    }

    .upload-documents {
      flex: 1;
      margin: 20px;

      .upload-document-container {
        .title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 20px;

          h4 {
            margin-bottom: 10px;
          }
        }

        .document {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: baseline;

          .c-field-container {
            margin-bottom: 25px;
            margin-right: 20px;
            flex: 1;
          }

          .button-container {
            margin-bottom: 25px;
          }
        }
      }

      .add-more-document {
        margin-top: 25px;
      }
    }
  }

  .address-dropdowns {
    display: flex;
    justify-content: space-between;

    .c-field-container {
      margin-right: 10px;
      flex: 1;
    }
  }

  .submit {
    margin: auto;
    width: 50%;
  }
}

// dashboard form end

.page-header {
  display: flex;
  flex-direction: column;
  margin: 30px 0 45px;

  &.row {
    flex-direction: row;
  }

  h1 {
    font-size: 28px;
    line-height: 28px;
    font-family: $font-bold;
    color: $black_2;
    margin: 0;
    flex: auto;
    align-self: center;
  }

  p {
    font-size: 14px;
    font-family: $font-regular;
    color: $black_2;
    margin: 5px 0 0;
  }

  .right {
    flex: 1;
  }
}

//Get Employee Page start

.topButtons {
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-end !important;

  .button-container {
    flex: 0;

    .c-button {
      width: 200px !important;
      margin: 10px !important;
    }
  }
}

//Get Employee Page Ends

// addemployee css start

.add-employee-form {
  display: flex;
  flex-direction: column;

  button.c-button.dash-btn {
    margin-top: 50px;
  }

  h1.form-title {
    margin-top: 30px;
  }

  .basic-detail-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;

    .c-field-container {
      flex: 1;
      margin-right: 10px;
      flex-basis: calc(25% - 10px);

      &:nth-child(9) {
        flex-basis: calc(50% - 10px);
      }
    }
  }

  .upload-container {
    display: flex;
    flex: 1;
    margin-top: 20px;
    align-items: center;
    width: 50%;

    .c-field-container {
      margin: 0;
      flex: 1;
    }
  }
}

.contact-datails-home {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;

  .c-field-container {
    flex: 1;
    margin-right: 10px;
    flex-basis: calc(50% - 10px);

    &:nth-child(5) {
      flex-basis: calc(25% - 10px);
    }

    &:nth-child(6) {
      flex-basis: calc(25% - 10px);
    }
  }
}

// .contact-details-work-container {
//   margin: 20px;
// }

.contact-details-work {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;

  .c-field-container {
    flex: 1;
    margin-right: 10px;
    flex-basis: calc(25% - 10px);
  }
}

.contact-details-work2-container {
  // margin: 20px;

  .contact-details-work2 {
    display: flex;
  }

  .c-field-container {
    width: 25% !important;
    margin: 10px;
  }
}

.add-emp-form {
  background: #fbfbfc;
  border: 1px solid #d5d5d5;
  border-radius: 49px;
  padding: 60px;
}

.cd-head {
  display: flex;
  justify-content: space-between;

  .c-field-container {
    margin-top: 15px;
  }
}

.c-table {
  // border-radius: 23px;
  // border: 1px solid $stroke-color;
  border: none;
  background-color: transparent;
  max-height: 100%;

  &.layout-auto {
    .ant-table {
      table {
        table-layout: auto !important;

        tr {

          td,
          th {
            width: max-content !important;

            &.ant-table-selection-column {
              padding-left: 10px !important;
              padding-right: 10px !important;
            }

            >div {
              width: max-content;
            }
          }
        }
      }
    }
  }

  &.rowSelection {
    .ant-table {

      .ant-table-tbody-virtual {
        td,
        col,
        th {
          &:first-child {
            padding: 12px 16px;
            width: 48px !important;
          }
        }
      }

      table {

        col {
          &:first-child {
            background-color: #3a3ab9;
            width: 48px !important;
          }
        }

        tr {

          td,
          th {
            &:first-child {
              padding: 0;
            }
          }
        }
      }
    }
  }

  .ant-table-title {
    // padding: 30px 40px;
    padding: 0;
    padding-bottom: 30px;
    display: flex;
    align-items: center;
    // width: 55%;
    flex-wrap: wrap;

    .search-view {
      flex: 0;
      // margin: 0 10px;
      font-size: 12px;
    }

    .card-view {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      border-radius: 7px !important;
      margin-bottom: 20px;
      justify-content: flex-start;
      overflow: hidden;
      flex-basis: 100%;

      .card {
        border-radius: 0 !important;
        background-color: $primary;
        padding: 15px 25px;
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: end;
        min-width: 200px;

        &:nth-child(odd) {
          background-color: $light-purple !important;
        }

        &:nth-child(even) {
          background-color: $lightest-purple !important;
        }

        &:last-child {
          // border-top-right-radius: 7px !important;
          // border-bottom-right-radius: 7px !important;
        }

        h4 {
          margin: 0;
          font-size: 20px;
          font-family: $font-space-regular;
          color: $black3;
          // margin-left: 15px;
        }

        span {
          color: $black3;
          font-size: 14px;
          line-height: 23px;
          font-family: $font-space-regular;
          letter-spacing: -0.5px;
          margin-bottom: 5px;
        }

        p {
          margin: 0;
          font-size: 12px;
          color: $white;
          font-family: $font-regular;
        }

        .card-circles {
          width: 100px;
          height: 150px;
          opacity: 0.2;
          position: absolute;
          top: -23px;
          right: -13px;
        }

        img {
          width: 30px;
          height: 30px;
          object-fit: contain;
        }

        .arrow {
          height: 15px;
          width: 15px;
          margin-left: 15px;
        }

        .card-logo {
          width: 150px;
          height: 200px;
          opacity: 0.1;
          right: 45px;
        }

        &.overview {
          width: 50%;
          background: linear-gradient(180deg, #3a3ab9 0%, #000080 100%);
          flex-direction: row;
          align-items: center;
        }

        .card-content {
          flex: 1;
        }
      }
    }
  }

  .ant-table {
    // background: $light-gray;
    background: transparent;
    // border-radius: 24px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    font-family: $font-medium;
    font-size: 10px;
    color: $light-black;

    table {
      // border-radius: 24px;
      // padding: 0 40px;
      table-layout: fixed !important;
      border: 0.5px solid $stroke-color;
      // border-left: 0.5px solid $stroke-color;

      tr {
        // border: 1px solid $stroke-color;

        th {
          padding: 10px;
          font-size: 12px;
          font-family: $font-bold;
          // border-top: 0.5px solid $stroke-color;
          white-space: nowrap;
          border-right: 0.5px solid $stroke-color;
          border-bottom: 0.5px solid $stroke-color;

          &:last-child {
            border-right: none;
          }
        }

        td {
          @extend th;
          padding: 10px;
          font-family: $font-regular;
          border-right: 0.5px solid $stroke-color;
          font-size: 11px;
          line-height: 18px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          border-bottom: 0.5px solid $stroke-color;

          >div {
            font-family: $font-regular;
            font-size: 11px;
            line-height: 18px;
            // width: max-content;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;

            &.break {
              width: auto;
            }
          }

          .ant-table-expanded-row-fixed {
            background-color: transparent;
          }

          .bold-text {
            // border-radius: 18px;
            // padding: 5px 10px;

            &.primary-back {
              background-color: $primary;
              color: $white;
              border-radius: 18px;
              padding: 5px 10px;
            }

            &.purple-back {
              background-color: $purple-shade;
              color: $primary;
              border-radius: 18px;
              padding: 5px 10px;
            }

            &.gray-back {
              background-color: $gray-shade;
              color: $black_2;
              border-radius: 18px;
              padding: 5px 10px;
            }

            &.green-back {
              background-color: $green-shade;
              color: $green;
              border-radius: 18px;
              padding: 5px 10px;
            }

            &.red-color {
              color: $redish;
            }

            &.green-color {
              color: $green-dark;
            }
          }
        }

        &:nth-child(2n) {
          td {
            background-color: #fafafa;
          }
        }

      }

      thead {
        background-color: $picker;

        tr {
          th {
            &:before {
              display: none;
            }
          }

          &:first-child {
            th {
              &:first-child {
                // border-top-left-radius: 24px !important;
              }

              &:last-child {
                // border-top-right-radius: 24px !important;
              }
            }
          }
        }
      }

      tbody {
        background-color: $white;

        tr {
          &:last-child {
            td {
              border-bottom: none;
              // border-bottom: 0.5px solid $stroke-color;
              font-family: $font-medium;
            }
          }

          td {
            &:last-child {
              border-right: none;
            }
          }
        }
      }
    }

    .primary-color {
      color: $primary;
    }

    .green-color {
      color: $green;
    }

    .red-color {
      color: $error;
    }

    .black-color {
      color: $black_2;
    }

    .medium-font {
      font-family: $font-medium;
    }

    .bold-font {
      font-family: $font-bold;
    }
  }

  &.expand {
    .ant-table {
      table {
        border: none;
        border-top: 0.5px solid $stroke-color;

        tr {
          &.ant-table-row-level-0 {
            td {
              border-top: 0.5px solid $stroke-color;

              &:first-child {
                border-left: 0.5px solid $stroke-color;
              }

              &:last-child {
                border-right: 0.5px solid $stroke-color;
              }
            }
          }

          &.ant-table-expanded-row-level-1 {
            td {
              padding: 0;
              background-color: $white !important;
              border-bottom: none;

              >div {
                border-bottom: 0.5px solid $stroke-color;
                border-left: 0.5px solid $stroke-color;
                border-right: 0.5px solid $stroke-color;
                margin-bottom: 12px;
                background-color: $light-back;
              }
            }
          }
        }

        thead {
          tr {
            th {
              &:first-child {
                border-left: 0.5px solid $stroke-color;
              }

              &:last-child {
                border-right: 0.5px solid $stroke-color;
              }
            }
          }
        }
      }
    }
  }

  &.expand-child {
    .ant-table {
      table {

        tr {
          &.ant-table-expanded-row-level-1 {
            >.ant-table-cell {
              padding: 0;

              .ant-table-wrapper {
                &.no-indent {
                  .ant-spin-nested-loading {
                    .ant-spin-container {
                      .ant-table {
                        margin: 10px;
                        margin-left: 10px !important;
                      }
                    }
                  }
                }
                .ant-spin-nested-loading {
                  .ant-spin-container {
                    .ant-table {
                      margin: 10px;
                      margin-left: 55px;
                    }
                  }
                }
              }
            }

          }
        }
      }
    }
  }

  .ant-table-pagination {
    &.ant-pagination {
      margin: 0;
      padding: 25px 25px;
      background-color: $light-gray;
      border-bottom-left-radius: 24px;
      border-bottom-right-radius: 24px;

      .ant-pagination-prev {
        display: none;
      }

      .ant-pagination-next {
        display: none;
      }

      .ant-pagination-item {
        min-width: 32px;
        height: 32px;
        border-radius: 18px;
        border: none;
        background-color: $gray;
        display: flex;
        justify-content: center;
        align-items: center;

        a {
          color: $dark-gray;
        }

        &.ant-pagination-item-active {
          background-color: $primary;

          a {
            color: $white;
          }
        }
      }
    }
  }

  .ant-spin-nested-loading {
    // border-radius: 24px;
    overflow: hidden;
  }
}

.no-data-table {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;

  .ant-spin-nested-loading {
    display: flex;
    flex-direction: column;
    flex: 1;

    .ant-spin-container {
      @extend .ant-spin-nested-loading;

      .ant-table-placeholder {
        width: 100%;
        display: contents
      }

      .ant-table-empty {
        @extend .ant-spin-nested-loading;

        .ant-table-container {
          @extend .ant-spin-nested-loading;

          .ant-table-content {
            @extend .ant-spin-nested-loading;

            table {
              flex: 1;
            }
          }
        }
      }
    }
  }

  table {
    table-layout: fixed !important;

    tr {
      td {
        >div {
          width: 100% !important;
        }
      }
    }
  }
}

.custom-table {
  width: 100%;
  overflow: scroll;
  border-top: 1px solid $dark-gray;
  border-bottom: 1px solid $dark-gray;
  max-height: 500px;
  // border-radius: 8px;
  box-shadow: -1px 9px 18px 0px $gray-shade;

  .scroll-area {
    @include scroll-x;
    @include scroll-y;
    max-height: 400px;
  }

  >div {
    border-bottom: 1px solid $dark-gray;
    padding: 10px;

    span {
      font-family: $font-regular;
      font-size: 12px;

      span {
        font-family: $font-bold;
        font-size: 12px;
      }
    }
  }

  table {
    width: 500px;
    max-height: 420px;
    overflow: scroll;
    padding: 10px;
    @include scroll-y;
    @include scroll-x;

    &.block {
      display: block;
      overflow: auto;
    }

    tr {
      border-bottom: 1px solid $dark-gray;
      width: 100%;
      flex: 0 !important;

      &:last-child {
        border-bottom: none;
      }

      &:nth-child(odd) {
        background-color: $white;
      }

      &:nth-child(even) {
        background-color: $gray;
      }

      th {
        width: 100%;
        flex: 1;
        white-space: nowrap;
        padding: 0 20px 0 0;
        font-size: 11px;
        line-height: 300%;
        min-height: 10px;
        font-family: $font-semiBold;

        &:first-child {
          padding-left: 10px;
        }
      }

      td {
        width: 100%;
        flex: 1;
        white-space: nowrap;
        padding: 0 10px;
        font-size: 10px;
        line-height: 250%;
        min-height: 10px;
        font-family: $font-regular;

        &:first-child {
          padding-left: 10px;
        }

        &.remarks {
          background-color: rgba($orange, .5);
        }

        &.warning {
          background: rgba($color: $redish, $alpha: 0.4);
        }
      }
    }
  }
}

.custom-table2 {
  width: 100%;
  overflow: scroll;
  // border-top: 1px solid $dark-gray;
  // border-bottom: 1px solid $dark-gray;
  max-height: 340px;
  // border-radius: 8px;
  // box-shadow: -1px 9px 18px 0px $gray-shade;
  @include scroll-y;
  @include scroll-x;

  table {
    // width: 500px;
    max-height: 500px;
    overflow: scroll;
    padding: 9px;
    width: 100%;
    min-width: 600px;
    border: 0.5px solid $dark-gray;
    @include scroll-y;

    th {
      width: 100%;
      flex: 1;
      white-space: nowrap;
      padding: 8px;

      font-size: 12px;
      // line-height: 300%;
      min-height: 10px;
      font-family: $font-semiBold;
      text-align: left;
      // border-bottom: 1px solid $dark-gray;

      &:first-child {
        // border-bottom: 1px solid $dark-gray;
        padding-left: 20px;
      }
    }

    tr {
      border-bottom: 0.5px solid $dark-gray;
      display: flex;
      flex: 1 !important;

      &:first-child {
        background-color: $picker;
      }

      &:last-child {
        border-bottom: none;
      }

      td {
        width: 100%;
        flex: 1;
        white-space: nowrap;
        padding: 12px 8px;
        font-size: 12px;
        // line-height: 250%;
        min-height: 10px;
        font-family: $font-regular;
        white-space: nowrap;

        &:first-child {
          padding-left: 20px;
        }
      }
    }
  }
}

.ant-table-filter-dropdown {
  font-family: $font-regular;
  border-radius: 10px;
  font-size: 14px;
  overflow: hidden;

  .ant-table-filter-dropdown-btns {
    border-radius: 0 0 10px 10px;
  }
}

.ant-btn-primary {
  color: $white;
  border: none;
  background: $primary;
  text-shadow: 0 -1px 0 $primary;
  box-shadow: 0 2px 0 $primary;
  font-size: 12px;
  font-family: $font-regular;

  &.lg {
    font-size: 16px;
  }

  &:hover {
    background: $primary;
  }

  &:active {
    background: $primary;
  }

  &:focus {
    background: $primary;
  }

  &:after {
    display: none;
  }
}

.ant-btn-link {
  color: $primary;

  &:hover {
    color: $primary;
  }

  &:active {
    color: $primary;
  }

  &:focus {
    color: $primary;
  }
}

.ant-checkbox-checked {
  .ant-checkbox-inner {
    background-color: $primary;
    border-color: $primary;
  }

  &:after {
    display: none;
  }
}

.ant-checkbox-input:focus+.ant-checkbox-inner,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner {
  border-color: $primary;
}

.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected {
  color: $primary;
  background-color: $white;
}

.c-tabs {
  .ant-tabs-nav {
    margin: 0 0 -20px 0;

    .ant-tabs-nav-wrap {
      .ant-tabs-nav-list {
        .ant-tabs-tab {
          background: $white;
          //opacity: 0.4;
          padding: 15px 15px 35px;
          min-width: 150px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 20px 20px 0px 0px;

          .ant-tabs-tab-btn {
            font-size: 14px;
            font-family: $font-regular;
            color: $black;
          }

          &.ant-tabs-tab-active {
            .ant-tabs-tab-btn {
              font-family: $font-medium;
              color: $primary;
            }
          }
        }
      }
    }

    &::before {
      display: none;
    }
  }
}

.ant-tabs-card.ant-tabs-bottom>.ant-tabs-nav .ant-tabs-tab+.ant-tabs-tab,
.ant-tabs-card.ant-tabs-bottom>div>.ant-tabs-nav .ant-tabs-tab+.ant-tabs-tab,
.ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab+.ant-tabs-tab,
.ant-tabs-card.ant-tabs-top>div>.ant-tabs-nav .ant-tabs-tab+.ant-tabs-tab {
  margin-left: 8px;
}

.employee-info {
  display: flex;
  margin-bottom: 50px;

  .cards {
    margin: 0 -10px 0;

    .card {
      &:last-child {
        flex: 1;
      }

      &.real-card-view {
        display: flex;
        flex-direction: column;

        .real-card-view-header {}

        .real-card-view-body {
          display: flex;
          flex: 1;
          flex-direction: column;
          justify-content: flex-end;

          span {
            color: $white;
            font-size: 14px;
            font-family: $font-regular;
            font-weight: 400;
            margin: 10px 0;
          }

          .line {
            height: 1px;
            width: 100%;
            background-color: $white;
            opacity: 0.32;
            margin: 0;
          }

          h3 {
            margin: 12px 0;
            color: $white;
            font-size: 20px;
            font-family: $font-regular;
            font-weight: 400;
          }

          a {
            color: $white;
            font-size: 14px;
            font-family: $font-regular;
            font-weight: 300;
            text-decoration: underline;
          }
        }
      }
    }
  }

  .info {
    flex: 1;
    margin-left: 20px;
    padding: 10px 30px;

    .info-list {
      display: flex;
      flex-wrap: wrap;

      .info-list-item {
        display: flex;
        flex-direction: column;
        width: 50%;
        padding: 20px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        span {
          font-size: 14px;
          font-family: $font-regular;
          color: $dark-gray;
          margin-bottom: 5px;

          &:last-child {
            font-size: 16px;
            color: $black;
            margin-bottom: 0;
          }
        }

        &:last-child {
          border-bottom: none;
        }

        &:nth-last-child(1) {
          border-bottom: none;
        }
      }
    }
  }
}

.payd-card {
  background-color: $primary;
}
.centiv-card {
  background-color: $black;
}

.card-icon {
  svg {
    width: 32px;

    .payd-card {
      background-color: $primary;

      path {
        &:first-child {
          fill: $primary;
        }
      }
    }

    .centiv-card {
      background-color: $primary;

      path {
        &:first-child {
          fill: $primary;
        }
      }
    }
  }
}

.card {
  border-radius: 21px;
  background-color: $light-gray;
}

.navbar-right {
  .ant-dropdown-trigger {
    color: $primary;
    font-family: $font-medium;

    &:hover {
      color: $primary;
    }

    &:active {
      color: $primary;
    }

    &:focus {
      color: $primary;
    }
  }
}

.ant-dropdown {

  //top: 120px !important;
  .header-dropdown {
    border-radius: 24px;
    overflow: hidden;
    padding: 0;

    .ant-dropdown-menu-item {
      padding: 15px 20px;

      .ant-dropdown-menu-title-content {
        font-size: 14px;
        font-family: $font-regular;
        color: $black;
      }
    }
  }

  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {}
}

.ant-dropdown-menu {
  border-radius: 0;
  padding: 0;

  .ant-dropdown-menu-item {
    padding: 15px 20px;
  }
}

.c-input {
  position: relative;

  &.ant-form-item-has-error {
    input {
      background-color: $primary;
      border-color: #ff4d4f !important;
    }
  }

  .MuiFormControl-root {
    width: 100%;

    .MuiInput-underline {
      &:before {
        display: none;
      }

      &:after {
        display: none;
      }
    }
  }

  .ant-input-affix-wrapper,
  input {
    color: $text-color;
    font-family: $font-medium;
    font-size: 15px;
    border-radius: 9px;
    height: 45px;
    border: 1px solid $stroke-color;
    padding: 0 15px;
    box-shadow: 0 2px 9px rgba(1, 3, 17, 0.03);

    &::placeholder {
      color: $text-color;
    }

    &:hover {
      border-color: $secondary;
    }

    &:focus {
      border-color: $secondary;
      //box-shadow: 0 0 0 2px rgba(199, 25, 32, 0.2);
      box-shadow: none;
    }
  }

  &.c-input-with-icon {
    .ant-form-item-control-input-content {
      position: relative;

      svg {
        position: absolute;
        left: 15px;
        z-index: 1;
        font-size: 18px;
        top: 13px;
        color: $text-color;
      }

      input {
        padding: 0 15px 0 45px;
      }
    }
  }

  .ant-form-item-control-input-content {
    .ant-input-affix-wrapper {
      input {
        height: auto;
      }
    }
  }
}

.ant-select-selection-search-input {
  height: 100% !important;
  box-shadow: none !important;
}

.search-view {
  width: 100%;
  position: relative;
  flex: 1;
  min-width: 280px;

  input {
    padding-right: 45px;
    font-size: 10px;
    border-radius: 9px;
    height: 32px;
    min-height: 32px;
  }

  svg {
    position: absolute;
    right: 15px;
    top: 6px;
    font-size: 18px;
    color: $secondary;
  }

  &.input-container {}
}

.c-select {
  .ant-select-selection-placeholder {
    display: flex;
    align-items: center;
  }

  &.ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border-color: $secondary !important;
    box-shadow: 0 0 0 2px rgba(134, 190, 64, 0.2) !important;
  }

  &.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: $secondary;
  }

  .ant-select-selector {
    height: 46px !important;
    border: none !important;
    border-radius: 9px !important;
    color: $black;
    // box-shadow: 0 2px 9px rgba(1, 3, 17, 0.03);

    .ant-select-selection-item {
      line-height: 44px;
      font-size: 14px;
      font-family: $font-medium;
      color: $black !important;
    }
  }
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  color: $white;
  background: $primary;
}

.ant-picker {
  width: 100%;
  height: 46px !important;
  border: none !important;
  border-radius: 7px !important;
  background: $picker !important;

  input {
    font-size: 14px;
    font-family: $font-regular;
    color: $black !important;
  }

  .ant-picker-suffix {
    line-height: 0;
  }

  .ant-picker-clear {
    line-height: 0;
  }

  .right {
    border-radius: 7px !important;
    border: none !important;
    background: $picker !important;
  }
}

.filter-container {
  padding: 10px;
  display: flex;
  flex-direction: column;

  .filter-buttons {
    display: flex;

    .mr {
      margin-right: 2px;
    }

    .ml {
      margin-left: 2px;
    }
  }
}

.filter-icon-selected {
  color: $primary;
}

.ant-upload.ant-upload-drag {
  border-radius: 24px;
  height: 300px;
}

.ant-upload-list {
  margin: 30px 0 20px 0;

  .ant-upload-list-item {
    border-radius: 9px;
    background-color: $white;

    &:hover {
      background-color: $white;
    }
  }
}

.ant-upload-drag-container {
  padding: 0 15px;
}

.flex3 {
  flex: 3 !important;
}

.flex2 {
  flex: 2 !important;
}

.flex1 {
  flex: 1 !important;
}

.flex09 {
  flex: 0.9 !important;
}

.flex08 {
  flex: 0.8 !important;
}

.flex07 {
  flex: 0.7 !important;
}

.flex06 {
  flex: 0.6 !important;
}

.flex05 {
  flex: 0.5 !important;
}

.flex04 {
  flex: 0.4 !important;
}

.flex03 {
  flex: 0.3 !important;
}

.flex02 {
  flex: 0.2 !important;
}

.flex01 {
  flex: 0.1 !important;
}

.flex0 {
  flex: 0 !important;
}

.flex-auto {
  flex: auto !important;
}

.flex-inherit {
  flex: inherit !important;
}

.flex-basis-10 {
  flex-basis: calc(10% - 15px) !important;
}

.flex-basis-20 {
  flex-basis: calc(20% - 15px) !important;
}

.flex-basis-30 {
  flex-basis: calc(30% - 15px) !important;
}

.flex-basis-40 {
  flex-basis: calc(40% - 15px) !important;
}

.flex-basis-50 {
  flex-basis: calc(50% - 15px) !important;
}

.flex-basis-60 {
  flex-basis: calc(60% - 15px) !important;
}

.flex-basis-70 {
  flex-basis: calc(70% - 15px) !important;
}

.flex-basis-80 {
  flex-basis: calc(80% - 15px) !important;
}

.flex-basis-90 {
  flex-basis: calc(90% - 15px) !important;
}

.flex-basis-100 {
  flex-basis: calc(100% - 15px) !important;
}
.flex-inherit {
  flex: inherit !important;
}
.w-10 {
  width: 10% !important;
}

.w-20 {
  width: 20% !important;
}

.w-30 {
  width: 30% !important;
}

.w-40 {
  width: 40% !important;
}

.w-50 {
  width: 50% !important;
}

.w-60 {
  width: 60% !important;
}

.w-70 {
  width: 70% !important;
}

.w-80 {
  width: 80% !important;
}

.w-90 {
  width: 90% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-100 {
  height: 100%;
}

.blueCard {
  background: linear-gradient(131.74deg, #8ea9da -5.13%, #54698d 82.26%);
  width: 250px;
  border-radius: 8px;
  padding: 10px 15px;
  margin-bottom: 20px;

  >div {
    color: $white;
    font-family: $font-space-regular;
    margin-left: 5px;

    >h4 {
      color: $white;
      font-family: $font-space-regular;
    }
  }
}

.c-icon {
  margin: 0 10px;
  color: $primary !important;
  transition: 0.3s ease all;
  min-width: 16px;
  min-height: 16px;
  cursor: pointer;
  align-self: center;
  width: 16px;
  height: 16px;

  svg {
    width: 16px;
    height: 16px;
  }

  &.large {
    width: 20px;
    height: 20px;

    svg {
      width: 20px;
      height: 20px;
    }
  }
  &.x-large {
    width: 24px;
    height: 24px;

    svg {
      width: 24px;
      height: 24px;
    }
  }
  &.xx-large {
    width: 28px;
    height: 28px;

    svg {
      width: 28px;
      height: 28px;
    }
  }
  &.notification {
    width: 35px !important;
    height: 35px !important;
    padding: 0;
    margin: -3px;
    // svg {
    //   width: 20px;
    //   height: 20px;
    // }
  }

  &.disabled {
    opacity: .5;
    cursor: not-allowed;
  }

  &.red {
    color: $error !important;
  }

  &.white {
    color: $white !important;
  }

  &.green {
    color: $green !important;
  }

  &.secondary {
    color: $secondary !important;
  }
}

// employee details page start
.edet-head {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  margin-bottom: 50px;

  .button-container {
    flex: 0.2;
  }
}

.esec-1 {
  display: flex;
  margin-bottom: 50px;

  .esec-field {
    flex: 1;
  }
}

hr {
  opacity: 0.2;
}

.p-det button.c-button.dash-btn {
  width: 30%;
}

.p-det p,
strong {
  font-size: 18px;
}

// employee details page end

//End Of Service Page Start

.end-of-service-form {
  // padding: 95px 150px 95px 150px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;

  .endServiceBtn {
    width: 30% !important;
    margin-top: 45px;
  }

  .checkbox-upload-button .button-container {
    flex: 0.3;
  }

  .checkbox-upload-button {
    display: flex;
    justify-content: space-between;
  }
}

//End Of Service Page End

// date input customization

input[type="date"] {

  &::-webkit-datetime-edit,
  &::-webkit-inner-spin-button,
  &::-webkit-clear-button {
    color: transparent;
    position: relative;
  }

  &::-webkit-datetime-edit-year-field {
    position: absolute !important;
    padding: 2px;
    color: $black;
    left: 60px;
    text-transform: uppercase;
  }

  &::-webkit-datetime-edit-month-field {
    @extend ::-webkit-datetime-edit-year-field;
    left: 30px;
  }

  &::-webkit-datetime-edit-day-field {
    @extend ::-webkit-datetime-edit-year-field;
    left: 4px;
  }

  &:not(.has-value) {
    &:before {
      color: $text-color;
      content: attr(placeholder);
    }

    &::-webkit-datetime-edit-year-field,
    &::-webkit-datetime-edit-month-field,
    &::-webkit-datetime-edit-day-field {
      color: transparent;

      &:focus {
        background-color: transparent;
      }
    }
  }
}

select::-ms-expand {
  color: red;
  display: none;
}

select::-ms-backdrop {
  color: red;
  display: none;
}

.c-form {
  margin: 0 15px;
}

.c-form,
.inner-form {
  display: flex;
  flex: 1;
  flex-wrap: wrap;

  .c-preview-list {
    .preview {
      margin-top: 0 !important;
    }
  }

  &.no-wrap {
    flex-wrap: nowrap;
  }

  &.col {
    flex-direction: column;

    &.sp-bt {
      align-content: space-between;
    }
  }

  &.sp-bt {
    justify-content: space-between;
  }

  &.sp-around {
    justify-content: space-around;
  }

  &.sp-even {
    justify-content: space-evenly;
  }

  &.flex-start {
    justify-content: flex-start;
  }

  &.flex-end {
    justify-content: flex-end;
  }

  .align-end {
    align-self: flex-end;
  }

  .justify-end {
    justify-self: end;
  }

  >div {
    flex: 1;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $font-semiBold;
    margin: 20px 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.mt0 {
      margin-top: 0;
    }
  }

  .ant-select-selection-item {
    height: auto
  }

  .form-sub-title {
    margin-top: 10px;
  }

  &.inline-form-2 {
    >div {
      min-width: calc(50% - 15px) !important;
    }
  }
  &.inline-form-3 {
    >div {
      min-width: calc(33.33% - 15px) !important;
    }
  }

  &.inline-form-3 {
    >div {
      min-width: calc(33.33% - 15px) !important;
    }
  }

  &.inline-form-4 {
    >div {
      min-width: calc(25% - 15px) !important;
    }
  }

  &.inline-form-5 {
    >div {
      min-width: calc(20% - 15px) !important;
    }
  }

  &.inline-form-6 {
    >div {
      min-width: calc(16.66% - 15px) !important;
    }
  }

  &.inline-form-7 {
    >div {
      min-width: calc(14.28% - 15px) !important;
    }
  }
}

.changePassText {
  cursor: pointer;
  color: $primary;
  font-size: 10px;
  font-family: $font-regular;
  margin: 3px 0 0px 5px;
}

.c-data,
.inner-data {
  @extend .inner-form;
  @include scroll-y;
  padding: 0;

  &.noSpace {
    display: contents;
  }

  &.tags {
    ul {
      li {
        span {
          width: fit-content;
          line-height: normal !important;
          &.ant-tag-red {
            color: #cf1322 !important;
          }
          &.ant-tag-green {
            color: #389e0d !important;
          }
          &.ant-tag-yellow {
            color: #d4b106 !important;
          }
        }
      }
    }
  }

  &.visible {
    overflow-y: visible;
  }

  &.flex-nowrap {
    flex-wrap: nowrap;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    flex: 1;

    li {
      display: flex;
      flex-direction: column;
      text-decoration: none;
      margin: 15px 40px 15px 0;
      min-width: 15%;

      span {
        &:first-child {
          font-size: 11px;
          line-height: 22px;
          font-family: $font-regular;
          color: $data-head;
          font-style: normal;
          font-weight: 400;
          line-height: 113.6%;
          letter-spacing: -0.02em;
        }

        &:last-child {
          font-size: 15px;
          line-height: 31px;
          font-family: $font-regular;
          color: $data-text;
          font-style: normal;
          font-weight: 500;
          letter-spacing: -0.02em;
        }
      }
    }

    h4 {
      font-size: 19px;
      line-height: 26px;
      color: $black;
      width: 100%;
    }

    h3 {
      width: 100%;
    }
  }
}

hr {
  width: 100%;
  color: $text-color;
  margin: 0;
}

.c-preview-list {
  width: 100%;

  .preview {
    display: flex;
    justify-content: space-between;
    border: 1px solid $dark-gray;
    border-radius: 8px;
    padding: 8px 10px;
    flex: 1;
    min-height: 40px;
    max-height: 40px;
    margin: 20px 20px 20px 0;
    flex: 1;

    .left {
      display: flex;
      align-items: center;
      cursor: pointer;
      overflow: hidden;

      .thumbnail {
        width: 25px;
        height: 25px;
        border-radius: 8px;

        img,
        svg {
          object-fit: contain;
          width: 25px;
          height: 25px;
        }
      }

      span {
        justify-content: space-between;
        align-items: center;
        font-family: $font-medium;
        margin: 0 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        height: 100%;

        div {
          font-size: 12px;
          line-height: 10px;
          margin-bottom: 5px;
        }

        p {
          font-size: 8px;
          line-height: 80.6%;
          color: #828486;
          margin-bottom: 0;
        }
      }
    }

    .icon {
      &:hover {
        color: red;
      }
    }
  }
}

.btn-group {
  display: flex;
  flex-wrap: wrap;
}

// .dashboard-cards {
//   display: flex;
//   flex-direction: row;
//   flex-wrap: wrap;
//   border-radius: 7px;

//   // margin: 0 -10px 45px;
//   .card {
//     min-height: 220px;
//     // width: calc(25% - 20px);
//     border-radius: 0 !important;
//     background-color: $primary;
//     // margin: 10px 10px;
//     padding: 25px 40px;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     position: relative;
//     // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
//     overflow: hidden;
//     flex: 1;
//     min-height: 300px;
//     justify-content: space-between;

//     &:first-child {
//       flex: 2;
//     }

//     &:nth-child(odd) {
//       background-color: $light-purple  !important;
//     }

//     &:nth-child(even) {
//       background-color: $lightest-purple  !important;
//     }

//     span {
//       color: $white;
//       font-family: $font-extra-light;
//       font-size: 18px;
//     }

//     h4 {
//       margin: 0;
//       font-size: 40px;
//       font-family: $font-bold;
//       color: $black3;
//     }

//     span {
//       color: $black3;
//       font-size: 18px;
//       line-height: 23px;
//     }

//     p {
//       margin: 0;
//       font-size: 12px;
//       color: $white;
//       font-family: $font-regular;
//     }

//     .card-circles {
//       width: 100px;
//       height: 150px;
//       opacity: 0.2;
//       position: absolute;
//       top: -23px;
//       right: -13px;
//     }

//     img {
//       width: 40px;
//       height: 40px;
//       object-fit: contain;
//     }

//     .card-logo {
//       width: 150px;
//       height: 200px;
//       opacity: 0.1;
//       right: 45px;
//     }

//     &.overview {
//       width: 50%;
//       background: linear-gradient(180deg, #3A3AB9 0%, #000080 100%);
//       flex-direction: row;
//       align-items: center;
//     }

//     .card-content {
//       flex: 1
//     }

//     // &.centiv {
//     //   background: linear-gradient(180deg, #313131 0%, #000000 89.54%);
//     //   justify-content: flex-end;
//     // }
//     // &.payd {
//     //   background-color: $primary;
//     //   justify-content: flex-end;
//     // }
//   }
// }

.ant-table-filter-dropdown {
  .filterDropdown {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: auto;
    min-height: auto;
    max-height: 150px;
    padding: 10px 15px;
    @include scroll-y;
    box-shadow: -2px -3px 5px $gray inset;
    flex-wrap: nowrap;

    &.fixed {
      height: 150px;
    }

    label {
      padding: 1px;
    }

    +div {
      // padding: 10px;
      display: flex;
      padding-top: 0;

      .button-container {
        margin: 10px;
        min-height: 30px;

        button {
          min-width: 100px;
          max-width: 100px;
        }
      }
    }
  }
}

.align-self-center {
  align-self: center;
}

.ant-alert {
  border-radius: 6px;

  span {
    svg {
      font-size: 12px;
    }
  }

  .ant-alert-content {
    font-size: 10px;
  }
}

.tr-height {
  height: 36px
}

.ant-picker-large .ant-picker-input>input {
  font-size: 12px;
}

.gap-20 {
  gap: 20px;
}

.gap-15 {
  gap: 15px;
}

.row-gap-15 {
  row-gap: 15px;
}

.row-gap-20 {
  row-gap: 20px;
}

.col-gap-15 {
  column-gap: 15px;
}

.col-gap-20 {
  column-gap: 20px;
}

.mb0 {
  margin-bottom: 0 !important;
}

.d-none {
  display: none !important;
}

.d-flex {
  display: flex !important;

  &.col {
    flex-direction: column !important;
  }

  &.row {
    flex-direction: row !important;
  }
}

.flex-initial {
  flex: initial !important;
}

.justify-start {
  justify-content: flex-start !important;
}

.justify-end {
  justify-content: flex-end !important;
}

.justify-center {
  justify-content: center !important;
}

.justify-between {
  justify-content: space-between !important;
}

.justify-around {
  justify-content: space-around !important;
}

.justify-evenly {
  justify-content: space-evenly !important;
}

.justify-baseline {
  justify-content: baseline !important;
}

.align-start {
  align-content: flex-start !important;
}

.align-end {
  align-content: flex-end !important;
}

.align-center {
  align-content: center !important;
}

.align-between {
  align-content: space-between !important;
}

.align-around {
  align-content: space-around !important;
}

.align-evenly {
  align-content: space-evenly !important;
}

.align-baseline {
  align-content: baseline !important;
}

.justify-items-end {
  justify-items: flex-end !important;
}

.justify-items-start {
  justify-items: flex-start !important;
}

.justify-items-center {
  justify-items: center !important;
}

.justify-items-baseline {
  justify-items: baseline !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.justify-self-start {
  justify-self: flex-start !important;
}

.justify-self-end {
  justify-self: flex-end !important;
}

.justify-self-center {
  justify-self: center !important;
}

.justify-self-baseline {
  justify-self: baseline !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-wrap {
  flex-wrap: wrap;
}

.text-wrap {
  text-wrap: wrap !important;
}

.bold-font {
  font-weight: bold;
}

.list {
  margin: 10px 0;
  padding: 0;
  list-style: none;

  &.appointment-list {
    .list-item {
      .button-groups {
        min-width: 340px;
      }
    }
  }

  .list-item {
    &.list-item-with-sub-list {
      flex-direction: column;
      align-items: flex-start;

      .list-item-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
      }

      .sub-list {
        padding: 0;
        width: 100%;
        margin: 15px 0 0;
      }
    }

    &.cursor {
      cursor: pointer;
    }

    background: $white;
    border: 0.5px solid $stroke-color;
    box-shadow: 0 2px 20px rgba(120, 125, 152, 0.08);
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    // cursor: pointer;
    .icon {
      width: 20px;
      height: 20px;
      margin-right: 12px;
      color: $secondary;

      &.one-to-one {
        color: #e8a91b;
      }

      &.group-lesson {
        color: $secondary;
      }
    }

    .image {
      width: 32px;
      height: 32px;
      border-radius: 20px;
      margin-right: 15px;
      object-fit: cover;
    }

    .icon {
      width: 20px;
      height: 20px;
      margin-right: 12px;
    }

    .text {
      margin: 0 10px 0 0;
      font-size: 12px;
      font-family: $font-medium;
      color: $black;
      text-transform: capitalize;

      //b {
      //  margin-right: 12px;
      //}
      .c-subject-tag {
        font-size: 11px;
        font-family: $font-bold;
        padding: 5px 10px;
        border-radius: 25px;
        margin-bottom: 5px;
        background: rgba(16, 199, 227, 0.1);
        color: #10c7e3;
        text-transform: uppercase;

        &.one-to-one {
          color: #e8a91b;
          background: #fff3d8;
        }
      }
    }

    .secondary-text {
      margin: 0 10px 0 0;
      color: $text-color;
      font-size: 12px;
      font-family: $font-medium;

      .c-subject-img {
        width: 30px;
        height: 30px;
        border-radius: 30px;
        border: 2px solid #ffffff;
        position: relative;
      }
    }

    .button-groups {
      margin: 0 0 0 15px;
      min-width: 200px;
      justify-content: flex-end;

      .c-button {
        padding: 5px 15px;
        font-size: 12px;
        min-height: 32px;
        text-decoration: none;
        margin: 0 15px 0 0;

        &:last-child {
          margin: 0;
        }
      }

      .c-button-urgent {
        padding: 15px 15px;
        font-size: 12px;
        min-height: 32px;
        text-decoration: none !important;
        margin: 0 15px 0 0;
      }
    }

    a {
      svg {
        font-size: 26px;
        fill: $text-color;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.c-checkbox-group {
  .ant-checkbox-wrapper {
    display: flex;
    align-items: flex-start;

    .ant-checkbox {
      font-size: 12px;

      .ant-checkbox-inner {
        border-radius: 4px;
        width: 16px;
        height: 16px;
      }

      &.ant-checkbox-checked {
        .ant-checkbox-inner {
          background-color: $secondary;
          border-color: $secondary;
        }

        &::after {
          border-color: $secondary !important;
        }
      }

      &:hover {
        .ant-checkbox-inner {
          border-color: $secondary !important;
        }
      }
    }

    &:hover {
      .ant-checkbox-inner {
        border-color: $secondary !important;
      }
    }

    span {
      &:last-child {
        font-size: 12px;
      }
    }
  }

  .ant-checkbox-input:focus+.ant-checkbox-inner {
    border-color: $secondary !important;
  }
}

.ant-space {
  &.ant-space-horizontal {
    &.ant-space-align-center {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .ant-space-item {
        margin-right: 0;

        &:first-child {
          flex: 1;
        }

        .button-container {
          margin-right: 0;
        }
      }
    }
  }
}

.ant-empty {
  align-self: center;
  min-height: auto !important;

  .ant-empty-description {
    font-size: 12px;
    font-family: $font-semiBold;
    color: $text-color;
  }
}

.table-row-red {
  background-color: rgba(255, 0, 0, 0.25);
}

.mt20 {
  margin-top: 20px !important;
}

.p20 {
  padding: 20px;
}

.sideCard {
  padding: 10px;
  height: 90px;
  margin: 10px 0px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
  background: linear-gradient(127.39deg, #f3f3f3 25.24%, #f0f5fe 71.66%);

  &:hover {

    span,
    h3 {
      color: $primary;
    }
  }
}

.profilePicCard {
  background: linear-gradient(127.39deg, #f3f3f3 25.24%, #f0f5fe 71.66%);
  padding: 10px;
  height: 90px;
  margin: 10px 0px;
}

.kamelPayCard {
  padding: 10px 20px 10px 30px;
  height: 90px;
  margin: 10px 0px;
  // align-items: center;
  // justify-content: space-around;
  align-items: center;
  // cursor: pointer;
  border-radius: 8px;
  background: linear-gradient(127.39deg, #f3f3f3 25.24%, #f0f5fe 71.66%);
}

.sideContent {
  width: 25%;
  min-width: 220px;
  margin-right: 30px;
}

.cardArrow {
  margin: 10px;
  width: 12px;
}

.cardImage {
  width: 20px;
  margin: 10px;
}

.centivCard {
  height: fit-content;
  width: 40px;
  margin-top: 11px;
  margin-right: 10px;
}

.expiryTag {
  display: flex;
  align-items: center;
  justify-content: center;
  // border: 1px solid $redish;
  border-radius: 60px;
  padding: 1px 10px;
  // box-shadow: 2px 2px 2px 2px rgba($redish, 0.6) inset, 2px 2px 2px 2px rgba($redish, 0.6) inset;
  background-color: rgba($redish, 0.2);
  margin-right: 15px;

  svg {
    width: 16px;
    height: 16px;
    color: $redish;
  }

  p {
    margin: 0;
    font-size: 8px;
    font-family: $font-regular;
    color: $redish;
  }
}

.filterActive {
  color: $primary;
}

.filterInactive {
  color: $filter-back;
}

.minW-200 {
  min-width: 200px;
}

.minW-300 {
  min-width: 300px;
}

.ant-checkbox-wrapper+.ant-checkbox-wrapper {
  margin-left: 0;
}

.notification-bell {
  display: flex;
  position: absolute;
  top: 0;
  right: 10px;

  svg {
    width: 22px;
    height: 22px;
  }
}

.ant-radio-group {

  .ant-radio-button-wrapper,
  .ant-radio-button-wrapper:hover() {
    margin: 10px 0;
    // border-radius: 6px;
    border-color: $primary !important;

    span {
      span {
        color: $primary !important;
      }
    }
  }

  .ant-radio-button-wrapper,
  .ant-radio-button-wrapper:hover() {
    border-color: $dark-gray !important;

    span {
      color: $dark-gray !important;
    }
  }

  .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
    .ant-radio-button.ant-radio-button-checked {
      border-color: $primary;
      background-color: $primary !important;
      outline: $primary !important;
      box-shadow: 0 0 3px $primary !important;
    }

    span {
      color: $white !important;
    }
  }
}

.ant-spin {
  max-height: 100% !important;

  &.ant-spin-spinning {
    .ant-spin-dot {
      .ant-spin-dot-item {
        background-color: $primary;
      }
    }

    .ant-spin-text {
      color: $primary;
    }
  }
}

.table-row-purple {
  background-color: $light-back;

  .ant-table-cell {
    background-color: $light-back !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.ant-steps-small {
  .ant-steps-item-title {
    font-size: 11px !important;
  }
}

.totals {
  border-bottom: 1px solid $dark-gray;
  padding: 0px;

  span {
    font-family: $font-regular;
    font-size: 12px;

    span {
      font-family: $font-bold;
      font-size: 12px;
    }
  }
}

.c-radio-button {
  &.ant-radio-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;

    .ant-radio-button-wrapper {
      margin: 0;
      min-height: 45px;
      display: flex;
      align-items: center;
      //border: 1.5px solid $dark-gray;
      background-color: transparent;
      border-color: $dark-gray;
      overflow: hidden;

      &.ant-radio-button-wrapper-checked {
        box-shadow: none;

        .ant-radio-button.ant-radio-button-checked {
          background-color: $primary;
        }

        &:not(.ant-radio-button-wrapper-disabled):before {}
      }

      &:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }

      &:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
  }
}

.mr-20 {
  margin-right: 20px !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.p25 {
  padding: 25px;
}

.clipboard-area {
  background-color: $gray;
  justify-content: space-between;
  border: 1px solid $dark-gray;
  border-radius: 10px;
  // margin-top: 60px;
  min-width: 280px;


  .clipboard-text {
    font-family: $font-regular;
    letter-spacing: 1.5px;
    font-size: 16px !important;
    flex: 1;
    background-color: $light-gray;
    padding: 10px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .clipboard-copied-text {
    margin: 0 !important;
    color: $dark-gray;
    padding: 10px;
  }

}

.two-fa-container {
  margin-top: 15px;

  .two-fa-inner-container {
    display: flex;
    flex-direction: row;

    .two-fa-container-left {
      padding-right: 15px;

      h4 {
        margin: 0 0 10px 0;
        font-family: $font-bold;
        font-size: 14px;
      }

      ul {
        padding: 0 0 0 20px;
        margin: 0;
        list-style: decimal;

        li {
          font-family: $font-regular;
          font-size: 14px;
          margin-bottom: 5px;
        }
      }
    }

    .two-fa-container-right {
      padding-left: 15px;
    }
  }

  .two-fa-input-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 15px 0;

    h6 {
      font-family: $font-bold;
      font-size: 14px;
      margin: 0;
    }

    .c-field-container {
      margin-bottom: 0;

      .otp-container {
        display: flex;
        justify-content: space-evenly;

        div {
          .otp-field {
            input {
              margin-bottom: 0 !important;
              margin-top: 0 !important;
            }

            &:first-child {
              input {
                margin-left: 0 !important;
              }
            }
          }
        }
      }

      .error {
        font-size: 12px;
        margin-top: 10px;
      }
    }
  }

  .two-fa-buttons {
    .button-container {
      margin-bottom: 0;

      button {
        height: 45px;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.right-side-detail {
  min-width: calc(60% - 30px);
  max-width: calc(100% - 30px);
  width: 100%;
  flex: 1;
}

.colorPicker {
  color: white !important;
  cursor: pointer !important;
  border: 0.5px solid silver;
  border-radius: 14px;
  padding: 15px;
  margin: 20px 0px;
  &:hover {
    padding: 16px;
  }
}

.radio-button-with-space {
  margin: 20px 0;
}

.variants {
  .variants-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 15px 0;
    .variants-header-title {
      margin: 0 !important;
      flex: 1
    }
    .button-container {
      margin: 0 0 0 15px;
    }
  }
  .variants-body {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 -15px;
    .variant {
      padding: 15px 30px;
      width: calc(50% - 30px);
      border: 1px solid #d9d9d9;
      border-radius: 10px;
      margin: 15px;
      .variants {
        .variant {
          flex: 1;
          padding: 0;
          border: none;
          margin: 0;
          width: 100%;
        }
      }
    }
  };
  .variant {
    .variant-header {
      margin: 15px 0 0;
      display: flex;
      flex-direction: row;
      .variant-header-title {
        margin: 0;
        flex: 1
      }
    }
    .variant-fields {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 25px 0;
      .c-field-container {
        margin: 0;
        flex: 1
      }
    }
  }
  &.productInventory {
    .variants-body {
      .variant {
        width: calc(100% - 30px);
        padding: 15px;
        .variant-header {
          margin: 0;
        }
        .variant-fields {
          flex-wrap: wrap;
          margin: 15px -15px;
          align-items: baseline;
          .variant-field {
            padding: 0 15px;
            width: 33.33%;
          }
        }
      }
    }
  }
}

.margin-top_zero {
  margin-top: 0 !important;
}
.margin-top_20 {
  margin-top: 20px !important;
}
.margin-top_15 {
  margin-top: 15px !important;
}
.margin-right_zero {
  margin-right: 0 !important;
}
.margin-bottom_zero {
  margin-bottom: 0 !important;
}
.margin-left_zero {
  margin-left: 0 !important;
}
.margin-left_10 {
  margin-left: 10px !important;
}
.margin-left_15 {
  margin-left: 15px !important;
}
.margin-left_20 {
  margin-left: 20px !important;
}
.margin_zero {
  margin: 0 !important;
}
.margin-top_zero {
  margin-top: 0px !important;
}

.quill-editor {
  .has-error {
    .quill {
      .ql-toolbar {
        border-color: $error !important;
      }
      .ql-container {
        border-color: $error !important;
      }
    }
  }
  .quill {
    .ql-toolbar {
      border: 1.5px solid $dark-gray !important;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    .ql-container {
      border: 1.5px solid $dark-gray !important;
      border-top: none !important;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    .ql-editor {
      min-height: 250px;
    }
  }
}

.template-textbody-editor {
  width: 100%;
  margin: 10px 0px 0px 0px !important;
}

.product-view-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  .product-view-col {
    width: calc(50% - 30px);
    padding: 0 15px;
  }
  .product-view-container-left {
    .product-slider {
      .product-slider-main-slider {
        .thumbs {
          .thumb {
            border: 2px solid transparent;
            border-radius: 5px;
            &.selected {
              border-color: #d4d4d4;
            }
            &:hover {
              @extend .selected
            }
          }
        }
        .product-slider-main-slider-slide {
          display: flex !important;
          justify-content: center;
          .product-slider-main-slider-slide-image {
            height: 420px;
            object-fit: contain;
          }
        }
        .slick-arrow {
          display: none !important;
        }
      }
      .product-slider-thumbnail-slider {
        //display: flex !important;
        //justify-content: center;
        margin-top: 25px;
        .product-slider-thumbnail-slider-slide {
          .product-slider-thumbnail-slider-slide-image {
            width: 52px;
            object-fit: contain;
            height: 52px;
          }
        }
        .slick-arrow {
          display: none !important;
        }
      }
    }
  }
  .product-view-container-right {
    .product-title {
      font-size: 24px;
      font-family: $font-bold;
      color: $black3;
      margin: 0;
    }
    .product-price {
      font-size: 18px;
      font-family: $font-regular;
      color: $black_2;
      margin: 15px 0;
    }
    .product-section {
      padding: 15px 0;
      .product-section-header {
        h3 {
          font-size: 16px;
          font-family: $font-semiBold;
          color: $black_2;
          margin: 0 0 15px;
        }
      }

      .product-section-body {
        .product-section-body-content {
          font-size: 14px;
          font-family: $font-regular;
          color: $black_2;
          margin: 0 0 15px;
        }

        .variant-list {
          display: flex;
          flex-direction: row;
          padding: 0;
          margin: 0 -5px;
          list-style: none;

          .variant-list-item {
            border: 1px solid $dark-gray;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 8px 10px;
            border-radius: 5px;
            margin: 0 5px;
            cursor: pointer;

            &.active-variant-list-item {
              border-color: $black3;
              background-color: $black3;
            }

            .variant-list-item-text {
              font-size: 12px;
              font-family: $font-regular;
              color: $black_2;
              margin: 0;

              &.active-variant-list-item-text {
                color: $white;
              }
            }
          }
        }
      }
    }

    .product-info-list {
      padding: 0;
      margin: 0;
      list-style: none;

      .product-info-list-item {
        display: flex;
        flex-direction: row;
        margin: 10px -15px;

        .product-info-list-item-text {
          flex: 1;
          color: $black_2;
          margin: 0 15px;
          font-size: 14px;
          font-family: $font-semiBold;

          &.title {
            color: $light-black;
            font-family: $font-semiBold;
          }
        }
      }
    }
  }
}

.count-card-main-container {
  margin: 0 -5px 30px;
  padding: 0 30px;

  .count-card-container {
    flex-direction: row;
    flex-wrap: wrap;
    display: flex;

    .card {
      box-shadow: rgba(149, 157, 165, 0.15) 0 0 5px 2px;
      border-radius: 8px;
      min-width: 120px;
      //width: 120px;
      margin: 5px;
      flex: 1;
      //background-color: red;

      .ant-card-body {
        padding: 12px;

        .ant-statistic {
          .ant-statistic-title {
            font-size: 12px;
          }

          .ant-statistic-content {
            font-size: 0;

            .ant-statistic-content-value-int {
              font-size: 14px;
            }
          }
        }
      }
    }

    .ant-skeleton {
      @extend .card;

      .ant-skeleton-button {
        width: 100%;
        min-height: 68.85px;
      }
    }
  }
}


.input-title {
  margin: 0 10px 5px 1px;
  color: $text-color;
  font-size: 10px;
  font-family: $font-regular;
}

.cardFields {
  display: flex;
  justify-content: space-between;

  >div {
    width: 100%;

    .card-field {
      input {
        width: 32px !important;
        height: 32px !important;
        border-radius: 10px !important;
        border-color: $text-color !important;
        margin: 0 6px;
        border: 1px solid;
        font-size: 12px;
      }

      &:first-child {
        input {
          margin-left: 0 !important;
        }
      }
    }
  }
}

.opt-field {
  flex: 1;

  input {
    padding: 0 !important;
    min-width: 42px !important;
    margin: 0 2.5px;
  }

  &:first-child {
    input {
      margin-left: 0;
    }
  }

  &:last-child {
    input {
      margin-right: 0;
    }
  }
}

.margin-right_15 {
  margin-right: 15px !important;
}

.margin-right_20 {
  margin-right: 20px !important;
}

.margin-bottom_15 {
  margin-bottom: 15px !important;
}

.margin-bottom_20 {
  margin-bottom: 20px !important;
}

.textORDate-filter-modal {
  margin-left: 15px;
}

.textORDate-filter-modal {
  margin-left: 15px;
}

.list-filter-modal {
  .ant-radio-group {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: auto;
    min-height: auto;
    max-height: 150px;
    padding: 10px 15px;
    @include scroll-y;
    box-shadow: -2px -3px 5px $gray-shade inset;
    flex-wrap: nowrap;

    &.fixed {
      height: 150px;
    }

    label {
      padding: 1px;
    }

    +div {
      // padding: 10px;
      display: flex;
      padding-top: 0;

      .button-container {
        margin: 10px;
        min-height: 30px;

        button {
          min-width: 100px;
          max-width: 100px;
        }
      }
    }
  }

  .ant-checkbox-group {
    @extend .ant-radio-group;
  }
}

.filter-title {
  font-size: 14px;
  font-family: $font-bold;
  margin-top: 10px;
  margin-right: 10px;
}

.filterList {
  margin-top: 10px;
  border-radius: 5px;
  font-size: 12px;
  font-family: $font-regular;
}

.cursor-pointer {
  cursor: pointer;
}

.small-red-text {
  margin: 0 10px 5px 1px;
  color: $redish;
  font-size: 12px;
  font-family: $font-regular;
}

.small-normal-text {
  font-size: 12px;
  font-family: $font-regular;
}

.small-link-text {
  font-size: 12px;
  font-family: $font-regular;
  color: $secondary;

  &:hover {
    color: $primary;
  }

}

.no-camera-access {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-height: 50vh;
  background-color: $darken-gray;
  border-radius: 10px;
}

.result-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 45px 0 25px 0;

  .result-image {
    width: 80px;
    height: 80px;
  }

  .result-body {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .result-title {
      text-align: center;
      margin: 25px 0 15px 0;
      font-family: $font-nexa-bold;
    }

    .result-subtitle {
      text-align: center;
      font-family: $font-nexa-regular;
      font-size: 16px;
      margin-bottom: 0;
    }
  }
}

.secondary-back {
  background-color: $secondary;
}

.alert-box {
  span {
    svg {
      font-size: 14px !important;
    }
  }

  .ant-alert-content {
    font-size: 14px;
    font-family: $font-medium;
  }
}

.ant-upload-wrapper {
  &.error {
    .ant-upload-drag {
      border-color: $error !important;
      background-color: rgba($color: $redish, $alpha: 0.1);
      margin-bottom: 8px;
    }
  }
  &.small-dragger {
    .ant-upload-drag-container {
      .ant-upload-drag-icon {
        margin-bottom: 10px;
        span {
          font-size: 38px;
        }
      }
      .ant-upload-text {
        font-size: 12px;
      }
      .ant-upload-hint {
        font-size: 10px;
        margin-bottom: 5px;
      }
    }
  }
}

.infinite-scroll {
  height: 400px;
  overflow: auto;
  border: 1px solid rgba(140, 140, 140, 0.35);
  border-radius: 9px;

  &.loading {
    .ant-spin-nested-loading {
      height: 400px;
    }
  }
}

.ant-list-item-countries {
  .ant-list-items {
    .ant-list-item {
      padding: 12px 16px;

      &:hover {
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
        cursor: pointer;
      }

      .ant-list-item-meta {
        align-items: center;

        .ant-list-item-meta-content {
          .ant-list-item-meta-description {
            font-size: 14px;
            color: $secondary;
          }
        }
      }
    }

  }
}

.ant-list-item-country {
  .ant-list-item {
    padding: 12px 16px;

    .ant-list-item-meta {
      align-items: center;

      .ant-list-item-meta-content {
        .ant-list-item-meta-description {
          font-size: 14px;
          color: $secondary;
        }
      }
    }
  }

}

.avatar-contain {
  img {
    object-fit: contain;
  }
}

.avatar-container {
  background-color: rgba($color: $secondary, $alpha: 0.5);
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    object-fit: contain;
    margin: 0 !important;
  }
}

.ant-grid-item-countries {
  .ant-card {
    padding: 10px;
    margin: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .ant-card-cover {
      margin-right: 10px;
      width: 32px;
      height: 32px;
      object-fit: contain;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .ant-card-body {
      padding: 0;
      flex: 1;

      .ant-card-meta-detail {
        .ant-card-meta-title {
          font-size: 14px;
          margin-bottom: 0;
          margin-top: 5px;
        }

        .ant-card-meta-description {
          font-size: 12px;
          color: $secondary;
        }
      }

    }
  }
}

.editable-row .ant-form-item-explain {
  position: absolute;
  top: 100%;
  font-size: 12px;
}

.text-wrap {
  text-wrap: wrap;
}

.text-wrap-balance {
  text-wrap: balance;
}

.error-row td {
  background: rgba($color: $redish, $alpha: 0.3) !important;
}

.warning-row td {
  background: rgba($color: $starColor, $alpha: 0.4) !important;
}

.approved-row td {
  background: rgba($color: $green-dark, $alpha: 0.3) !important;
}

.rejection-row td {
  background: rgba($color: $redish, $alpha: 0.5) !important;
}

.text-prominent {
  font-family: $font-medium;
  color: $secondary;
}

.checkbox-auth-confirmation {
  display: flex;
  flex-direction: column;

  label {
    .ant-checkbox {
      align-self: flex-start;
      top: 8px;
    }
  }
}

.overflow-visible {
  overflow: visible;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-auto {
  overflow: auto;
}

.relative {
  position: relative !important;
}

.manage-access-selection-button {
  position: absolute !important;
  right: 0 !important;
  top: 0 !important;
  margin: 10px !important;

  button {
    padding: 6px !important;
    height: auto !important;
    min-height: auto !important;
    min-width: 0 !important;

    span {
      font-size: 10px !important;
      line-height: 12px !important;
    }
  }
}

.margin-right_0{
  margin-right: 0px !important;
}

.kyc-images {
  display: flex;
  flex-direction: row;
  margin-left: -10px;
  margin-right: -10px;
  .ant-image {
    margin: 10px;
    flex: 1;
    min-height: 50px;
    max-height: 100px;
    min-width: 50px;
    max-width: 100px;
  }
  .kyc-image {
    width: 100%;
    height: 100%;
    object-fit: cover;

    &:first-child{
      //margin-left: 0;
    }
    &:last-child{
      //margin-right: 0;
    }
  }
  .ant-image-mask {}
}

.merge-container {
  position: relative;
  .merge-container-body {

    .merge-container-column {
      position: relative;
      min-width: 50%;
      border-right: 1px solid #f0f0f0;
      .merge-container-column-header {
        border-bottom: 1px solid #f0f0f0;
        padding: 0 15px;
      }
      .merge-container-column-body {
        padding: 15px;
        //.info-list-container {
        //  .info-list {
        //    margin: 0;
        //    padding: 0;
        //    list-style: none;
        //    display: flex;
        //    flex-direction: row;
        //    flex-wrap: wrap;
        //    .info-list-item {
        //      display: flex;
        //      flex-direction: column;
        //      align-items: flex-start;
        //      margin: 10px;
        //      .info-list-item-label {
        //        margin-bottom: 5px;
        //        font-size: 12px;
        //        font-family: $font-regular;
        //        color: $data-head;
        //        font-style: normal;
        //        font-weight: 400;
        //        letter-spacing: -0.02em;
        //      }
        //      div {}
        //    }
        //  }
        //}
      }
      &:last-child{
        border-right: none;
      }
    }
  }
  .merge-container-footer {
    border-top: 1px solid #f0f0f0;
    //padding: 0 15px;
  }
}


.popup-container {
  .info-list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    .info-list-item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin: 10px;
      flex: 1;
      .info-list-item-label {
        margin-bottom: 5px;
        font-size: 12px;
        font-family: $font-regular;
        color: $data-head;
        font-style: normal;
        font-weight: 400;
      }
      .info-list-item-text {
        margin-bottom: 0;
        font-size: 14px;
        font-family: $font-medium;
        color: $black;
        font-style: normal;
        font-weight: 600;
      }
    }
  }
}
.margin-top_0_16{
  margin-top : 0  !important;
  margin-bottom: 16px !important;
}

.signatureCanvas {
  border: 1px solid black;
  width: 100%;
  min-height: 100px;
  height: 100px;
  position: relative;

  canvas, .ant-image {
    width: 100%;
    min-height: 100px;
    height: 100px;
  }

  canvas {
    display: flex;
  }

  .ant-image {
    display: none;
  }

  &.loading {

    .ant-image {
      display: flex !important;
      z-index: 9;
      position: absolute;
    }

    canvas {
      display: none;
    }

  }

}

.no-image {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 80px;
  width: 100px;
  background-color: $gray-shade;
  border-radius: 6px;
  margin: 0 10px;
}

.capture-documents {
  .ant-image {
    display: flex;
    margin: auto;
    max-width: 120px;
  }

}

.video-icon-overlay {
  position: absolute;
  top: 12px;
  right: 12px;
  color: $black3;
  cursor: pointer;
  z-index: 99;

  &.second {
    left: 12px;
  }
}

.advance-salary-reference-text {
  color: $secondary;
  font-family: $font-bold;
  font-size: 14px;
  text-align: end;
  cursor: pointer;
}

.pending-count {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 60px;
}

.topic-container {
  display: flex;
  flex-direction: row;
  max-height: 450px;
  min-height: 300px;
  .topic-inner-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    .topic-inner-container-header {
      padding: 8px 12px 9px;
      color: rgba(0, 0, 0, 0.88);
      background: #ffffff;
      border-bottom: 1px solid rgba(5, 5, 5, 0.06);
      border-radius: 8px 8px 0 0;
      h4 {
        margin: 0;
       font-size: 14px;
         font-family: $font-bold;
         color: $black3;
      }
    }
    .topic-inner-container-body {
      flex: 1;
      display: flex;
      flex-direction: column;
      &.topic-inner-container-body-scroll {
        overflow-y: scroll;
        padding: 5px 0;
      }
      .topic-inner-container-body-list {
        padding: 0;
        display: flex;
        flex-direction: column;
        flex: 1;
        &.empty {
          justify-content: center;
        }
        .topic-inner-container-body-list-item {
          display: flex;
          flex-direction: column;
          //align-items: center;
          min-height: 32px;
          padding: 5px 12px;
          transition: all 0.3s;
          &:hover {
            background-color: rgba(0, 0, 0, 0.04);
            cursor: pointer;
          }
          .topic-inner-container-body-list-item-radio-container {
            margin-top: 5px;
          }
        }
      }
    }
  }
  .topic-container-left {

  }
  .topic-container-right {}
  .topic-container-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
    button {
      margin: 5px 0;
    }
  }
}

.ant-steps-overflow-auto {
  .ant-descriptions-view {
    overflow: auto !important;
  }
}

.child-offer-wrapper {
  border: 1px dotted gray;
  padding: 15px;
  border-radius: 8px;
  position: relative;
}

.child-offer-text {
  position: absolute;
  top: -13px;
  background: white;
  color: darkgray;
  padding: 0px 12px;
}

.word-break {
  word-break: break-all;
}

.currency-input-prefix-container {
  display: flex;
  align-items: center;
  border-right: 1px solid #aeaeae;
  padding-right: 10px;
  img {
  }
  span {
    margin-left: 10px;
    font-size: 12px;
    font-family: $font-medium;
    color: $black3;
  }
}


.ce-divider {
  border-color: $primary !important;
}


.primary-color {
  color: $primary;
}

.salary-count-card {
  display: flex;
  justify-content: space-between !important;
  flex-direction: column;
  height: 100%;
}
.count-card {
  .ant-card-body{
    padding: 24px 14px !important;
  }
}