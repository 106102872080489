.navbar {
  background-color: transparent;
  border: none;
  border-radius: 8px;
  padding: 20px 0;
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  width: 100%;

  .menu-button {
    margin-right: 15px;
    background: transparent;
    border: none;
    padding: 0;
    font-size: 22px;
    display: none;
    color: $primary;
    align-self: center
  }

  .back-button {
    @extend .menu-button;
    display: flex;
    cursor: pointer;
  }

  .main-nav {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    justify-content: flex-end;

    .navbar-left {
      flex: 2;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      overflow: hidden;
      flex-direction: column;
      margin: 10px 0;
      min-width: 200px;
      span {
        display: flex;
        align-items: center;
        overflow: hidden;
        width: 100%;
        svg {
          margin-bottom: 15px;
        }
      }

      h1 {
        margin: 0 0 0 0;
        font-family: $font-semiBold  !important;
        font-size: 24px;
        color: $black_2;
        white-space: nowrap;
        line-height: 113.6%;
        letter-spacing: -0.02em;
      }

      p {
        margin: 10px 0 0 0;
        font-family: $font-regular;
        color: $light-black;
        font-size: 12px;
        line-height: 113.6%;
        letter-spacing: -0.02em;
      }
    }

    .navbar-right {
      align-self: center;
      justify-content: flex-end;
      margin: 0;
      .button-container {
        margin: 0 0 0 15px;
      }
      .btn-group {
        .button-container {
          margin: 0 15px 0 0;
          &:last-child{
            margin: 0;
          }
        }
      }
    }
  }
}
