@import "cButton/cButton";
@import "cField/cField";


.loading {
  justify-content: center;
  align-items: center;
  display: flex;
  flex: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
  background: rgba(255, 255, 255, 0.5);
  .ant-spin {
    .ant-spin-dot-item {
      background-color: $primary;
    }
  }
}
